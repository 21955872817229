import ArticlePreview1 from "../assets/images/articles/1.jpg";
import ArticlePreview2 from "../assets/images/articles/2.jpg";
import ArticlePreview3 from "../assets/images/articles/3.jpg";
import ArticlePreview4 from "../assets/images/articles/4.jpg";

type Article = {
  slug: string; // url
  title: string; // заголовок для списка и для страницы
  author: string; // автор для списка и для страницы статьи
  content: JSX.Element; // собственно контент статьи
  preview: string; // изображение для списка
};

export const ARTICLES: Article[] = [
  {
    slug: "peters-morals",
    title: "Грубые нравы властелина «царства варваров», или почему современники считали Петра дикарем",
    author: "Розман Илана, Университет ИТМО",
    content: (
      <>
        <p className="article_content">
          Петр Великий запомнился не только своими грандиозными реформами, но и эксцентричными манерами. Царя не понимали
          ни дома, ни за границей: в России ходили слухи о том, что на престол взошел самозванец, а европейские правители, боясь
          беспорядка, который всюду нес за собой варварский государь, выносили из дома всю хорошую мебель на время его визитов.
          Чем же Петр так шокировал современников?
        </p>
        <h2 className="article_subtitle">«Хрупкие косточки»: как Петр запугал королеву Пруссии</h2>
        <p className="article_content">
          В 1717 году Петр отправился в Берлин и уже по пути успел огорчить прусскую королевскую чету. Петр решил остановиться
          в загородном доме королевы, чем очень ее раздосадовал: государыня с любовью и большим трепетом обустраивала этот
          изысканный дворец не для того, чтобы русские ввергли его в хаос. Приезд русской делегации ознаменовался обедом,
          на котором и произошел довольно курьезный инцидент. Петр страдал от судорог, и очередной приступ застал его прямо
          за столом, с ножом в руках. Пораженный судорогой, Петр начал размахивать ножом перед лицом королевы, ужасно
          напугав ее. Когда судорога отпустила царя, и он поспешил подать королеве руку, чтобы успокоить ее, очередная волна
          конвульсий охватила Петра, и он сжал руку королевы с такой силой, что она невольно вскрикнула от боли. В ответ царь
          благосклонно рассмеялся и заявил, что косточки у немецкой королевы более хрупкие, нежели у его супруги.{" "}
        </p>
        <img className="article_image" src={ArticlePreview1} alt="" />
        <h2 className="article_subtitle">Угонщик карет</h2>
        <p className="article_content">
          В том же 1717 году московский царь решил посетить и Париж. К предстоящему визиту Петра и его многочисленной свиты
          здесь тоже относились без энтузиазма. Во-первых, прием капризной и странной русской делегации сулил множество
          расходов. Во-вторых, французский регент близко дружил с королем Англии, к которому Петр питал взаимную личную
          неприязнь. Однако обременительности своего визита монарх не ощущал и в Париже чувствовал себя как дома: непостижимо
          много пил и ел и не позволял обстоятельствам вмешиваться в свои планы. Задумав куда-то поехать, Петр брал первую
          попавшуюся карету — привыкший к полной независимости царь абсолютно не интересовался, чье транспортное средство попало
          в его распоряжение. Жертвой такой неучтивой самостоятельности Петра стала госпожа Матиньон, выехавшая на прогулку:
          стоило ей оставить свой экипаж без присмотра, царь тут же направился в нем в Булонь. Возвратившись с прогулки, госпожа
          Матиньон с удивлением обнаружила, что осталась без кареты.{" "}
        </p>
        <h2 className="article_subtitle">Наглость — второе счастье, или как Петр вдову Людовика XIV навещал</h2>
        <p className="article_content">
          В Париже с великим государем приключился еще один любопытный случай. Осматривая женскую школу, Петр решительно захотел
          увидеть ее основательницу, жену покойного Людовика XIV, маркизу де Ментенон. Узнав о любопытстве царя, маркиза
          занавесила все окна и легла в постель. Петра такое положение дел совершенно не смутило — войдя в спальню госпожи
          де Ментенон, он открыл все шторы на окнах, отдернул занавеси постели, внимательно осмотрел маркизу и удалился, чем
          очень сильно ее оскорбил.{" "}
        </p>
        <h2 className="article_subtitle">Фанат анатомии, некрофил и садист</h2>
        <p className="article_content">
          Следующая история произошла с Петром в 1698 году, во время Великого Посольства. И хотя историки до сих пор спорят
          о ее достоверности, уже сам факт существования такого мифа многое говорит нам о том, каким великий государь предстал
          в глазах современников. Дело было в Лейдене, где Петр посетил анатомический театр. К анатомии Петр питал большой
          интерес: царь часто присутствовал на операциях, сам обучался хирургии, а к спиртованию относился как к искусству.
          Любовь Петра простиралась так широко, что однажды, увидев превосходно препарированный труп ребенка, он восторженно
          поцеловал его. Поэтому посещение анатомического театра привело царя в сильное эмоциональное возбуждение, чего нельзя
          было сказать о его спутниках. Заметив неуместную брезгливость своих спутников, Петр решил преподать подданным урок
          и приказал им разгрызть зубами сухожилия трупа.
        </p>
        <h2 className="article_subtitle">Настоящий аскет</h2>
        <p className="article_content">
          Иностранцев всегда поражала простота привычек Петра: могущественный государь вел себя в совершенно несвойственной
          царской особе манере. Сложно представить, каково было удивление датского посланника Юста Юля, когда во время
          торжественной аудиенции он увидел Петра в ночном колпаке — царь совершенно не заботился о церемониях. Петр изумлял
          современников и тем, что не признавал пышных обедов. Более того, царь любил обычные солдатские столовые и совсем
          не брезговал кислыми щами.
        </p>
      </>
    ),
    preview: ArticlePreview1
  },
  {
    slug: "2",
    title: "Петр Великий и Великое Посольство",
    author: "Кондрашов Пётр, Университет ИТМО",
    content: (
      <>
        <p className="article_content">
          Петр Первый за свою жизнь объездил много городов России, поэтому его смело можно назвать царём-путешественником.
          Но больше всего в памяти народа осталось его знаменитое Великое Посольство в Западную Европу. Но как русский царь
          решился на такое предприятие, ведь никто из предшественников Петра так много не выезжал за границу? Да и в целом,
          почему Петр Великий был так обращён в сторону Запада, как никто до него?
        </p>
        <p className="article_content">
          То, что интеграция Западной культуры в русскую жизнь началась только с Петра Первого — это неверное утверждение.
          Тесные отношения начали завязываться ещё при его отце — Алексее Михайловиче, а в какой-то степени и раньше, при его
          деде Михаиле Фёдоровиче Романове; то есть отчасти толчком к началу этого процесса был конец Смутного времени
          и воцарение новой династии в начале XVII века (но конечно же, некоторые контакты случались и раньше). Постепенно
          в русской жизни (правда пока только у царей и их приближённых) стали появляться иностранные вещи, такие как часы или
          кареты. Всё больше людей стало ориентироваться на Запад, в том числе и в высших кругах власти: первыми западниками
          считались боярин Б.И. Морозов (воспитатель, а затем фаворит Алексея Михайловича), главы посольского приказа А.С.
          Матвеев (его убили во время Стрелецкого бунта в 1682 году на глазах у 10-летнего Петра) и В.В. Голицын (фаворит
          царевны Софьи, в дальнейшем — сподвижник Петра). Так что почва для реформ Петра подготавливалась задолго до его
          правления.
        </p>
        <p className="article_content">
          Таким образом, мысль о поездке на Запад рождалась сама из обстановки и направления деятельности Петра. Он с детства
          был окружён пришельцами с Запада, учился их мастерствам, знал их язык; в письмах к матери подписывался как Petrus,
          а лучшую галеру воронежского флота назвал «Principum».{" "}
        </p>
        <p className="article_content">
          Немаловажную роль в обращении Петра к Западу сыграла Немецкая слобода. Усиленный спрос привлекал в Москву множество
          иноземных техников, врачей, мастеров, заводчиков, офицеров и солдат. Ещё при Иване IV Грозном эти пришельцы образовали
          под Москвой на реке Яузе Немецкую слободу на восточной окраине города. Именно в этом месте любил проводить время юный
          Петр, невольно знакомясь тем самым с западноевропейской культурой.
        </p>
        <p className="article_content">
          Такая тяга к немцам легко соотносилась с ортодоксальными взглядами русской православной церкви. С её точки зрения все
          неправославные люди — «плохие». Но мусульмане были «лучшие из худших», так как они просто не знают истинного Бога,
          т.е. их можно было крестить. А ещё протестантов остерегались меньше, чем католиков: протестанты Бог отлучил от себя
          за протест, так что они не были опасными и их можно было подпускать к себе. Так, немцы были неконкурентоспособными
          с точки зрения религии и власти, потому что они были протестантами. И именно поэтому в своём путешествии в Европу Петр
          посещал Германию, Англию, Голландию — страны, где преобладал протестантизм.
        </p>
        <p className="article_content">
          Так, в 1697 году было организовано Великое Посольство. Основной целью было налаживание дипломатических контактов
          с европейскими дворами по поводу шедшей тогда русско-турецкой войны (1686-1700), то есть скрепить прежние и завязать
          новые дружественные отношения с западноевропейскими государствами. Это была, так сказать, публичная цель Посольства.
          Но была и другая задача: послам (в числе которых был Лефорт, Головин, Возницын и другие) была передана инструкция,
          в которой было наказано сыскать за границей на морскую службу капитанов, поручиков и корабельных мастеров.
        </p>
        <p className="article_content">
          Так, в 1697 году, отправляя 19 дворян в Венецию, московская грамота оповещала о намерениях Петра «во Европе
          присмотреться новым воинским искусствам и поведениям»; однако князь Б.И. Куракин, бывший в числе этих дворян, писал
          в своём дневнике о том, что они учились там математике, астрономии, навигации, механике, фортификации, а также много
          плавали.
        </p>
        <p className="article_content">
          Волонтёрам предписывалось обучиться морским чертежам или картам, владению компасом, научиться управлять судном как
          в бою, так и при обычном ходе, знать все снасти и инструменты, во время пребывания за границей искать всякого случая
          быть на море во время боя, а по возвращении в Москву привезти с собой по два искуссных мастера морского дела.
          Правительство даже проводило специальные «акции»: если дворянин обучит за границей морскому делу своего дворового
          человека, он получал за него из казны 100 рублей.
        </p>
        <p className="article_content">
          Таким образом, Великое посольство со своей многочисленной свитой под прикрытием дипломатического поручения было одной
          из снаряжавшихся тогда в Москве экспедиций на запад с целью всё нужное там высмотреть, вызнать, перенять европейское
          мастерство и сманить европейского мастера.
        </p>
        <img className="article_image" src={ArticlePreview2} alt="" />
        <p className="article_content">
          Возвращаясь к самому Петру Великому, он, проходя сухопутную и морскую службу, принял за правило первому обучаться
          всякому новому делу, чтобы показать пример и обучать других. Поэтому приказывая дворянской молодёжи ехать
          в командировку за границу, он не мог не поехать сам. Но ехал он туда не как любознательный путешественник, чтобы
          полюбоваться диковинами чужой культуры, а как рабочий, желавший ознакомиться с тем, с чем был ещё незнаком.
        </p>
        <p className="article_content">
          Но чтобы полноценно и собственноручно обучиться мастерствам, Петр пребывал в дипломатической свите под именем простого
          матроса Петра Михайлова. Как только он попал за границу, сразу принялся доучиваться артиллерии. В Кенигсберге ему даже
          выдали аттестат о том, что он проявил себя как осторожный, мужественный и бесстрашный огнестрельный мастер и художник.
        </p>
        <p className="article_content">
          25-летний Петр стал быстро приобщаться к западноевропейской культуре, посещая, например, обеды, на которых
          он не изменял своему домашнему поведению. Первый выезд русского царя в большой европейский свет случился на пути
          из Германии в Голландию в городке Коппенбурге. Там проходил ужин у курфюстин ганноверской и бранденбургской. Петр
          сначала намного растерялся, но потом оправился, разговорился, очаровал хозяек, перепоил их по-московски. Во время
          разговора Петр Великий признавался, что не любит ни музыки, ни охоты, а любит запускать фейерверки, строить корабли
          и ходить по морям — в общем хозяйки насчитали 14 ремёсел. Гости танцевали, причём московские кавалеры приняли корсеты
          немецких дам за их рёбра. Петр Первый приподнял за уши и поцеловал 10-ти летнюю принцессу, будущую мать Фридриха
          Великого. В итоге принцессы потом не скупились на рассказы о вынесенном впечатлении, говоря о том, что царь был
          красив, умён, но груб, неопрятен, так что оценка получилась двусмысленной: государь очень хороший и одновременно очень
          плохой.
        </p>
        <p className="article_content">
          После Германии Петр Первый решил ознакомиться с Голландией и Англией, как со странами, в которых была наиболее развита
          военно-морская и промышленная техника. Сначала Петр неделю работал в Саардаме (ныне Зандан, город на западе
          Нидерландов) плотником на частной верфи, нанимая каморку у случайно встреченного кузнеца. Между делом царь осматривал
          фабрики, заводы, лесопильни, а также навещал семьи голландских плотников, уехавших в Москву.
        </p>
        <p className="article_content">
          Но Петру Великому не удалось полностью скрыть своего титула (даже под одеждой простого голландского рабочего), так что
          вскоре ему уже не было в Саардаме прохода от любопытных зевак, желавших посмотреть на царя-плотника из далёкой России.
          Так что Петр решил перебраться в Амстердам и вместе со своей свитой устроился на верфь Голландской Ост-Индской
          компании. Это оказалось возможным благодаря амстердамскому бургомистру Витзену (в современной аналогии, мэру города
          Амстердам), или как его называли по-русски «Вицын», который не раз бывал в Москве. Вместе с царём плотничать
          отправилось ещё 10 человек, включая А.Д. Меншикова; остальные участники Великого посольства пошли делать парусы или
          мачты, либо стали матросами. Там они заложили целый фрегат и через 9 недель спустили его на воду.
        </p>
        <p className="article_content">
          Помимо работы у Петра была насыщенная культурная программа: он посещал комедию, присутствовал на торжественных обедах
          с фейерверком. Царь постоянно находился в движении: осматривал всевозможные редкости и достопримечательности, фабрики,
          заводы, кунсткамеры, госпитали, воспитательные дома, военные и торговые суда, обсерваторию, принимал у себя или ездил
          в гости к иноземцам, общался с корабельными мастерами.
        </p>
        <p className="article_content">
          Поработав месяца четыре в голландии, Петр Первый узнал основу корабельного мастерства, но голландские мастера ему
          показались слабыми, так что в начале 1698 года он отправился в Англию для продолжения своего обучения. Английский
          король Вильгельм Оранский его хорошо принял, даже подарил ему свою лучшую яхту. Петр перебрался на королевскую верфь
          в городок Дептфорд (недалеко от Лондона), где ему отвели помещение в частном доме близ верфи, оборудовав его как
          положено такому высокому гостю. Оттуда он часто выезжал в разные районы Британии. В Вуличе (ранее отдельный город
          и верфь, ныне часть Лондона) наблюдал за приготовлением артиллерийских снарядов и учился метанию бомб. В Портсмуте
          осматривал военные корабли, у которых записывал число пушек, их калибр и вес ядер. А у острова Вайта для Петра
          Великого было проведено показательное морское сражение. Бывал он и в Оксфорде.
        </p>
        <p className="article_content">
          Члены Великого Посольства вместе со своей главой часто ездили в британскую столицу: бывали в Королевском обществе
          наук, ходили в театры, посещали обсерваторию, обедали и общались с разными лицами. Несколько раз они бывали
          в Лондонском Тауэре, который привлекал их своим монетным двором и тюрьмой; однажды даже свиту пригласили в парламент.
          Сохранилось интересное замечание Петра об этом посещении Верхней палаты британского парламента, где на троне сидел
          король, а рядом с ним на скамьях сидели его вельможи. Выслушав с помощью переводчика их прения, царь сказал своим
          русским спутникам: «Весело слушать, когда подданные открыто говорят своему государю правду; вот чему надо учиться
          у англичан.»
        </p>
        <p className="article_content">
          Возвращаясь в Дептфорд, Петр с компанией в основном только веселились и пили. В итоге после их трёхмесячного
          пребывания, хозяин дома описал то, в каком состоянии они оставили жилище: полы были запачканы, мебель поломана,
          занавески оборваны, картины прорваны (т.к. служили мишенью для стрельбы)... Всего повреждений насчитали до 350 фунтов
          стерлингов (по тогдашнему курсу).
        </p>
        <p className="article_content">
          Таким образом, видно, что отправившись на Запад за его наукой. московские ученики не подумали, как держаться
          в тамошней обстановке. Следя за мастерствами, они не всматривались в нравы и порядки. Они не заметили, что у себя
          в Немецкой слободе они общались с изгоями того мира, в который они попали сейчас — так что между немцами в слободе
          и немцами в Германии была большая разница...
        </p>
        <p className="article_content">
          Кто знает, может быть, Петр со свитой обратили бы на это внимание, но царю было не суждено продолжить путешествие.
          В мае 1698 года он приехал в Вену, но уже в июле он поспешил домой в Москву, отказавшись от поездки в Италию. Причиной
          тому был стрелецкий бунт и заговор его сестры, царевны Софьи. Петра Великого ждали новые вызовы: сразу по приезде
          он принялся за жестокий розыск нового мятежа и наказание его участников. А почти сразу после этого в 1700 году
          началась Северная война, предприятие ещё более тяжёлое и трудное.
        </p>
        <p className="article_content">
          Из своего заграничной поездки Петр привёз до 900 человек всевозможных мастеров, от вице-адмирала до корабельного
          повара, но важнее были впечатления, которые он приобрёл за 15 месяцев своей командировки. По возвращении из заграницы
          царь начал модернизировать русскую жизнь на европейский лад. Так что кто знает, какая судьба ждала бы Россию, не будь
          этого Великого Посольства?..
        </p>
      </>
    ),
    preview: ArticlePreview2
  },
  {
    slug: "fashion",
    title: "Модный приговор по-петровски: естественность как антитренд",
    author: "Розман Илана, Университет ИТМО",
    content: (
      <>
        <p className="article_content">
          Петр I был великим законодателем: его широкомасштабные реформы затронули даже те сферы жизни народа, которые обычно
          находятся за пределами государственного влияния. К примеру, Петр в корне изменил женскую моду. В этом материале
          разбираемся в том, как эпоха Петра превратила естественность в антитренд.{" "}
        </p>

        <h2 className="article_subtitle">Время затянуть пояса</h2>
        <p className="article_content">
          Женские наряды допетровской эпохи не отличались изысканностью — предпочтение отдавали свободному крою
          и многослойности. С приходом Петра все изменилось: европеизация русской культуры, к которой стремился государь,
          диктовала новые модные тенденции. При Петре женщинам пришлось переодеться из традиционной длинной свободной рубахи
          в тугие корсеты и пышные юбки. Более того, дамам пришлось привыкать не только к новым силуэтам, но и к декольте —
          наряды европейского образца, которые велел носить Петр I, предполагали ношение глубокого выреза. Понимая, что новые
          модные веяния не будут восприняты с энтузиазмом, Петр решил их узаконить: в 1700 году был издан указ «О ношении платья
          на манер Венгерского», в 1701 году — «О ношении всякого чина людям Немецкого платья и обуви, и об употреблении
          в верховой езде Немецких седел», а в 1702 году — указ «О ношении парадного платья в праздничные и церемониальные дни».
          Однако даже слово закона не помогло сделать европейское платье всеобщим трендом: изначально новой моде следовала лишь
          придворная знать, большая же часть женского населения к таким радикальным переменам оказалась не готова.
        </p>
        <img className="article_image" src={ArticlePreview3} alt="" />
        <h2 className="article_subtitle">Пышному наряду — пышная прическа</h2>
        <p className="article_content">
          Прически Петровской эпохи соответствовали нарядам: в почете была помпезность. В начале XVIII века в моду входит
          «фонтанж» — высокая прическа с вплетенными в волосы нитями жемчуга или драгоценных камней. Иногда и без того пышная
          прическа дополнялась одноименным головным убором — чепцом, состоящим из ряда накрахмаленных кружев, между которыми
          распределялись локоны. Кроме того, в моде были парики. А.Н. Толстой в своем романе «Петр Первый» описывает типичную
          прическу Петровской эпохи так: «Вздрагивали нитки жемчугов на ее белом парике, натянутом на каркас из китового уса,
          столь высокий, что, даже подняв руки, она не могла бы коснуться его верхушки». Сложно представить, на какие жертвы
          дамам приходилось идти в погоне за модой! Мало того, что носить такую роскошь на своей голове было тяжело физически,
          вдобавок изысканную прическу легко могли населить блохи — для красоты парики присыпали пудрой, которая благодаря
          своему натуральному составу зачастую становилась лакомством для блох.
        </p>

        <h2 className="article_subtitle">Токсичный макияж</h2>
        <p className="article_content">
          Мода на искусственность не обошла стороной и макияж. К примеру, в погоне за популярной в те времена аристократической
          бледностью придворные дамы шли на отчаянные меры: лицо покрывали толстым слоем токсичных свинцовых белил, которые
          придавали коже мертвенно-белый оттенок. Поверх густого слоя токсичной пудры часто наносили не менее густой слой румян.
          Макияж был настолько плотным, что порой снимать его приходилось специальным ножом! По последнему слову моды красились
          не только знатные дамы, но и купчихи. Однако модные тенденции они интерпретировали иначе: стремясь выглядеть
          по последней моде, купчихи красили зубы в черный цвет. Идеал красоты купеческого мира хорошо описан в «Путешествии
          из Петербурга в Москву»: «Прасковья Денисовна, его новобрачная супруга, бела и румяна. Зубы как уголь. Брови в нитку,
          чернее сажи».{" "}
        </p>
        <h2 className="article_subtitle">Новый язык флирта</h2>
        <p className="article_content">
          Флирт в Петровскую эпоху также подчинялся модным тенденциям: на смену естественному языку пришел искусственный «язык
          мушек». Дамы часто клеили на лицо бархатные мушки, имитирующие родинки. Место, куда прилеплялись мушки, не было
          случайным. Каждая мушка имела свое значение. Например, чтобы показать мужчине свой интерес мушку клеили в угол глаза,
          а чтобы обозначить свое желание получить поцелуй от спутника — мушку помещали на верхнюю губу.
        </p>
      </>
    ),
    preview: ArticlePreview3
  },
  {
    slug: "one-throne-for-two",
    title: "Один трон на двоих: начало правления Петра I",
    author: "Юдаева Оксана, Университет ИТМО",
    content: (
      <>
        <p className="article_content">
          В Оружейной палате Московского Кремля находится уникальный и весьма своеобразный экспонат: двойной трон, изготовленный
          в 1682 году. Появление его связано с тем, что в том году пришлось венчать на царство сразу двух царей: 15-тилетнего
          Ивана Алексеевича и 11-тилетнего Петра Алексеевича, сыновей Алексея Михайловича. Но давайте сделаем шаг назад, чтобы
          увидеть, как так вышло, а заодно поближе рассмотрим трон и церемонию венчания на царство.
        </p>
        <p className="article_content">
          Итак, Алексей Фёдорович (Тишайший) — сын Михаила Фёдоровича и его жены Евдокии, второй русский царь из династии
          Романовых. От первого брака с Марией Милославской у него было 13 детей, из которых нас интересуют трое: Софья, Фёдор
          и Иван. От второго брака с Натальей Нарышкиной у него было трое детей, из которых нас интересует только один — Пётр
          (будущий Пётр I). Алексей Фёдорович правил с 1645 по 1676 год, а после его смерти на престол взошёл его сын Фёдор.
        </p>
        <img className="article_image" src={ArticlePreview4} alt="" />
        <p className="article_content">
          Фёдор Алексеевич был венчан на царство 18 (28) июня 1676 года, правил 6 лет и умер в 1682 году — и это положило начало
          одному из главных триллеров в истории России. Детей у Фёдора Алексеевича не было, и престол наследовал следующий
          по старшинству Иван Алексеевич. Но он был с детства хилым и болезненным, мало способным к управлению страной, поэтому
          Нарышкины — родственники второй жены Алексея Фёдоровича — предложили вместо него выбрать царём Петра, его
          единокровного брата. Подобный поворот событий ущемлял интересы царевны Софьи — дочери Алексея Фёдоровича от первого
          брака — и её родственников. Результатом столкновения интересов двух родов стал стрелецкий бунт, в ходе которого было
          выдвинуто требование признать Ивана первым царём, а Петра — вторым. Во избежание повторения погромов, бояре
          согласились с таким решением, и в июне 1682 года оба мальчика были венчаны на царство. Поскольку, в силу малого
          возраста обоих братьев, они не могли полноценно управлять государством, Софья признавалась при них регентшей
          и фактической правительницей государства.
        </p>
        <p className="article_content">
          С учётом новых вводных церемония венчания на царство приобрела необычные черты. Всё необходимо было удваивать:
          проводимые обряды, тронные места в Успенском соборе, царские регалии. Для Ивана использовали уже существовавшие
          регалии, а для Петра сделали новые, но по образцам старых: скипетр и парадный венец, который в описях получил название
          «Шапка Мономаха второго наряда».
        </p>
        <p className="article_content">
          И, разумеется, удвоить пришлось трон. Но вместо ещё одного, дополнительного трона, изготовили один трон с двумя
          сиденьями: тот самый, который сегодня выставлен в Оружейной палате. Трон этот, на изготовление которого пошло больше
          двухсот килограммов серебра, похож на маленький дворец, украшенный символами власти: двуглавыми орлами, державами
          с крестами, львами и коронами. За спинкой трона было ещё третье место, которое предназначалось для наставников царей,
          так что по сути трон был даже не двойным, а тройным. В спинке трона, со стороны Петра Алексеевича, было проделано
          специальное окошечко. Через него тот, кто сидел позади трона, руководил действиями братьев во время официальных
          церемоний. Чаще всего это место занимала царевна Софья.
        </p>
        <p className="article_content">
          Для церемонии венчания на царство в Успенском соборе было устроено специальное «чертожное место» — покрытое багряной
          тканью возвышение, на которое вели 12 ступеней. На сайте музеев Московского Кремля можно найти подробный рассказ
          об этой церемонии. Мы не будем приводить его целиком, ограничимся только отрывком с описанием ключевого момента:
          «Начался молебен, в ходе которого патриарх [Иоаким] и оба царя сидели под царским чертогом посереди храма. После
          молебна патриарх указал облачить Ивана и Петра в царские одежды, после чего с молитвой надел на царей кресты и бармы.
          На головы царей патриарх торжественно возложил венцы, вручил им державы и скипетры. Боговенчанным государям пропели
          многолетие. Затем патриарх произнес поучение царям: призвал их свято хранить православную веру, подданных своих любить
          и жаловать, делать все по суду правому и помнить о суде Божьем».
        </p>
        <p className="article_content">
          Следующие семь лет после этой церемонии Пётр провёл преимущественно в удалённых от Москвы сёлах, где сначала играл
          с потешными войсками, а затем всерьёз занялся их военной подготовкой. Вскоре из царской забавы выросли два полноценных
          полка: Преображенский и Семёновский, которые впоследствии стали самыми известными полками всей российской армии.
        </p>
        <p className="article_content">
          Активность Петра, связанная с обучением войск военному делу, и его приближающееся совершеннолетие особенно беспокоили
          Софью. Стоило Петру перешагнуть порог совершеннолетия, и Софье пришлось бы расстаться с властью. Растущая мощь войска
          Петра также могла стать помехой на пути Софьи к единоличной власти. Софья решила избавиться от брата при помощи верных
          ей стрельцов. Но среди них нашлись сторонники Петра, которые предупредили его о готовящемся нападении. Пётр укрылся
          в Троице-Сергиевом монастыре. За этим последовало непродолжительное противостояние царевны и будущего царя: Пётр
          приказывал стрелецким полкам явиться к монастырю, а Софья угрожала им смертной казнью в случае исполнения этого
          приказа. Но в конце концов стрельцы встали на сторону законного царя. Софью вскоре после этого отправили под стражу
          в Новодевичий монастырь, где была пострижена в монахини.
        </p>
        <p className="article_content">
          Формально Иван Алексеевич был соцарём Петра вплоть до своей смерти в 1696 году, но фактически для Петра с этого
          момента начинается новый виток его власти — теперь уже единоличной.
        </p>
      </>
    ),
    preview: ArticlePreview4
  }
];
