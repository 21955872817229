import { Link } from "react-router-dom";
import BigLogoImage from "../components/BigLogoImage";

const About = () => {
  return (
    <main className="layout_main about flex row mobile--column content--space-between mobile-content--start items--start">
      <div className="main_section left about desktop-only flex column content--space-between items--start">
        <div className="section_logo">
          <Link to="/">
            <BigLogoImage />
          </Link>
        </div>

        <div className="itmo_logo_container">
          <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 176.9 69.3">
            <g>
              <path
                d="M9.4,59c0.2-0.4,0.2-0.8,0.1-1.1c-0.1-0.2-0.3-0.4-0.5-0.5c-0.6-0.2-1.1,0.1-1.4,0.9l-2.3,5.6L2,58.2
                        c-0.2-0.4-0.4-0.6-0.7-0.7c-0.3-0.1-0.6-0.1-0.9,0.1C0.2,57.7,0,58,0,58.3c0,0.3,0.1,0.6,0.3,0.9L4.2,66c-0.2,0.4-0.4,0.8-0.6,1
                        c-0.2,0.2-0.4,0.3-0.7,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.4-0.1C2.1,67.2,2,67.1,2,67.1c-0.6-0.2-1,0-1.2,0.5
                        c-0.2,0.6,0,1,0.6,1.4c0.4,0.2,1,0.3,1.7,0.3c0.8,0,1.4-0.3,1.9-0.9c0.5-0.6,1-1.5,1.5-2.7L9.4,59L9.4,59z"
              ></path>
              <path
                d="M12.6,69.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-4h5.2v4c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3
                        c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-9.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.8v3.7h-5.2v-3.7c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8C11.9,69.1,12.2,69.2,12.6,69.2L12.6,69.2z"
              ></path>
              <path
                d="M25.2,69.2c0.3,0,0.7-0.2,1-0.6l5.4-7.3v6.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.5,0.3-0.8v-9.6c0-0.7-0.3-1.1-1-1.1c-0.4,0-0.7,0.2-1,0.6l-5.3,7.4v-6.9c0-0.3-0.1-0.6-0.3-0.8
                        c-0.2-0.2-0.5-0.3-0.8-0.3c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8
                        C24.6,69.1,24.9,69.2,25.2,69.2L25.2,69.2z"
              ></path>
              <path
                d="M43.5,62.9c0.4-0.2,0.8-0.5,1-0.9c0.3-0.5,0.4-1,0.4-1.5c0-2.1-1.5-3.1-4.3-3.1c-1,0-1.9,0.1-2.7,0.2c-0.7,0.1-1,0.5-1,1.2
                        v9.2c0,0.7,0.3,1.1,1,1.1c0.8,0.1,1.8,0.2,2.9,0.2c3.2,0,4.8-1.2,4.8-3.6c0-0.7-0.2-1.3-0.6-1.8C44.5,63.4,44.1,63.1,43.5,62.9
                        L43.5,62.9z M40.5,62.3h-1.7v-3.1c0.5-0.1,1-0.1,1.5-0.1c1.5,0,2.3,0.5,2.3,1.5C42.7,61.8,42,62.3,40.5,62.3L40.5,62.3z M42.6,67
                        c-0.4,0.3-1,0.4-1.8,0.4c-0.7,0-1.4,0-1.9-0.1V64h1.9c1.7,0,2.5,0.6,2.5,1.7C43.2,66.3,43,66.8,42.6,67L42.6,67z"
              ></path>
              <path
                d="M55.8,68.9c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-4.8v-3.1h3.7c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3h-3.7v-2.8h4.7c0.3,0,0.6-0.1,0.7-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-5.7c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8
                        v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3H55C55.3,69.2,55.6,69.1,55.8,68.9L55.8,68.9z"
              ></path>
              <path
                d="M66.4,61.1c0-1.3-0.4-2.3-1.2-2.9c-0.8-0.6-1.9-0.9-3.3-0.9c-1,0-1.9,0.1-2.6,0.2c-0.7,0.1-1,0.6-1,1.2v9.3
                        c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-3c0.3,0,0.8,0.1,1.4,0.1
                        c1.4,0,2.5-0.3,3.3-1C65.9,63.4,66.4,62.4,66.4,61.1L66.4,61.1z M60.4,59.3c0.5-0.1,0.9-0.1,1.4-0.1c1.6,0,2.3,0.6,2.3,1.9
                        c0,1.4-0.8,2.1-2.4,2.1c-0.5,0-1,0-1.3-0.1V59.3L60.4,59.3z"
              ></path>
              <path
                d="M75.9,66.7c-0.8,0.5-1.6,0.7-2.4,0.7c-1,0-1.8-0.4-2.4-1.1c-0.6-0.7-0.9-1.8-0.9-3c0-1.2,0.3-2.2,0.9-3
                        c0.9-1.2,2.5-1.4,4.6-0.6c0.7,0.3,1.2,0.2,1.4-0.3c0.2-0.5,0.1-1-0.4-1.3c-0.8-0.5-1.8-0.8-3.2-0.8c-1.7,0-3.1,0.6-4,1.7
                        c-1,1.1-1.5,2.6-1.5,4.4c0,1.9,0.5,3.3,1.4,4.4c0.9,1.1,2.3,1.6,4.1,1.6c1.4,0,2.6-0.3,3.5-1c0.3-0.2,0.5-0.5,0.5-0.8
                        c0.1-0.3,0-0.5-0.1-0.7C77,66.4,76.5,66.3,75.9,66.7L75.9,66.7z"
              ></path>
              <path
                d="M88.3,57.4c-0.4,0-0.7,0.2-1,0.6l-5.3,7.4v-6.9c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.4,0-0.6,0.1-0.8,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.8,0.3c0.3,0,0.7-0.2,1-0.6l5.4-7.3v6.8
                        c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-9.6C89.4,57.7,89,57.4,88.3,57.4
                        L88.3,57.4z"
              ></path>
              <path
                d="M101.4,58.3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3h-8c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
                        c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.8,0.3h2.9v8.7c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.5,0.3-0.8v-8.7h2.9c0.3,0,0.6-0.1,0.8-0.3C101.3,58.9,101.4,58.6,101.4,58.3L101.4,58.3z"
              ></path>
              <path
                d="M110.2,67.2h-4.8v-3.1h3.7c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3
                        h-3.7v-2.8h4.7c0.3,0,0.6-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-5.7
                        c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h5.9c0.3,0,0.6-0.1,0.7-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C110.8,67.3,110.5,67.2,110.2,67.2L110.2,67.2z"
              ></path>
              <path
                d="M122.2,58.3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3h-8c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
                        c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.8,0.3h2.9v8.7c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.5,0.3-0.8v-8.7h2.9c0.3,0,0.6-0.1,0.8-0.3C122.1,58.9,122.2,58.6,122.2,58.3L122.2,58.3z"
              ></path>
              <path
                d="M138.4,68.1v-9.6c0-0.7-0.3-1.1-1-1.1c-0.4,0-0.7,0.2-1,0.6l-5.3,7.4v-6.9c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
                        c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.8,0.3c0.3,0,0.7-0.2,1-0.6l5.4-7.3
                        v6.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3C138.3,68.7,138.4,68.4,138.4,68.1L138.4,68.1z"
              ></path>
              <path
                d="M149.3,57.4h-8c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.8,0.3h2.9v8.7
                        c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-8.7h2.9c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C149.9,57.5,149.6,57.4,149.3,57.4L149.3,57.4z"
              ></path>
              <path
                d="M162,57.4c-0.4,0-0.8,0.2-1.1,0.7l-3.3,5.4l-3.4-5.3c-0.3-0.5-0.7-0.7-1.1-0.7c-0.3,0-0.5,0.1-0.7,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.9v9.6c0,0.7,0.3,1.1,1,1.1c0.7,0,1-0.4,1-1.1v-6.6l2.4,3.7c0.3,0.4,0.6,0.6,1,0.6c0.4,0,0.7-0.2,1-0.6
                        l2.3-3.8v6.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-9.6
                        c0-0.3-0.1-0.6-0.3-0.8C162.6,57.5,162.4,57.4,162,57.4L162,57.4z"
              ></path>
              <path
                d="M175.4,58.9c-1-1.1-2.4-1.7-4.2-1.7c-1.8,0-3.2,0.6-4.2,1.7c-1,1.1-1.5,2.6-1.5,4.4c0,1.8,0.5,3.2,1.5,4.4
                        c1,1.1,2.4,1.7,4.2,1.7c1.8,0,3.2-0.6,4.2-1.7c1-1.1,1.5-2.6,1.5-4.4C176.9,61.5,176.4,60.1,175.4,58.9L175.4,58.9z M173.7,66.2
                        c-0.6,0.8-1.4,1.1-2.5,1.1c-1.1,0-1.9-0.4-2.5-1.1c-0.6-0.8-0.9-1.8-0.9-3s0.3-2.2,0.9-3c0.6-0.8,1.4-1.1,2.5-1.1
                        c1.1,0,1.9,0.4,2.5,1.1c0.6,0.8,0.9,1.8,0.9,3C174.6,64.5,174.3,65.5,173.7,66.2L173.7,66.2z"
              ></path>
              <path d="M55.8,26.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C54.7,24,55.8,25,55.8,26.3L55.8,26.3z"></path>
              <path d="M66.9,11.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C65.4,7.9,66.9,9.4,66.9,11.3L66.9,11.3z"></path>
              <path
                d="M76.9,16.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C75.4,12.8,76.9,14.4,76.9,16.3L76.9,16.3z
                        "
              ></path>
              <path
                d="M86.9,21.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C85.4,17.8,86.9,19.4,86.9,21.3L86.9,21.3z
                        "
              ></path>
              <path
                d="M76.9,26.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C75.4,22.8,76.9,24.4,76.9,26.3L76.9,26.3z
                        "
              ></path>
              <path
                d="M96.9,26.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C95.4,22.8,96.9,24.4,96.9,26.3L96.9,26.3z
                        "
              ></path>
              <path d="M95.8,36.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3C94.7,33.9,95.8,35,95.8,36.3L95.8,36.3z"></path>
              <path d="M105.8,31.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C104.7,29,105.8,30,105.8,31.3L105.8,31.3z"></path>
              <path
                d="M106.9,21.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C105.4,17.8,106.9,19.4,106.9,21.3
                        L106.9,21.3z"
              ></path>
              <path
                d="M96.9,16.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C95.4,12.8,96.9,14.4,96.9,16.3L96.9,16.3z
                        "
              ></path>
              <path d="M85.8,31.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C84.7,29,85.8,30,85.8,31.3L85.8,31.3z"></path>
              <path
                d="M106.9,11.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C105.4,7.9,106.9,9.4,106.9,11.3
                        L106.9,11.3z"
              ></path>
              <path d="M115.7,6.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C114.7,4,115.7,5.1,115.7,6.3L115.7,6.3z"></path>
              <path d="M115.7,26.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C114.7,24,115.7,25,115.7,26.3L115.7,26.3z"></path>
              <path d="M85.8,11.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C84.7,9,85.8,10,85.8,11.3L85.8,11.3z"></path>
              <path d="M75.8,36.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C74.7,33.9,75.8,35,75.8,36.3L75.8,36.3z"></path>
              <path
                d="M66.9,21.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C65.4,17.8,66.9,19.4,66.9,21.3L66.9,21.3z
                        "
              ></path>
              <path
                d="M53.5,14.5c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8C51.7,15.3,52.5,14.5,53.5,14.5L53.5,14.5z
                         M53.5,12.8c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5C56.9,14.4,55.4,12.8,53.5,12.8L53.5,12.8z"
              ></path>
              <path
                d="M44.8,31.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C44.2,29.9,44.8,30.5,44.8,31.3L44.8,31.3z
                        "
              ></path>
              <path
                d="M44.8,21.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C44.2,19.9,44.8,20.6,44.8,21.3L44.8,21.3z
                        "
              ></path>
              <path
                d="M64.8,31.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C64.2,29.8,64.8,30.5,64.8,31.2L64.8,31.2z
                        "
              ></path>
              <path d="M64.8,1.4c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C64.2,0,64.8,0.6,64.8,1.4L64.8,1.4z"></path>
              <path
                d="M84.7,41.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C84.1,39.9,84.7,40.5,84.7,41.2L84.7,41.2z
                        "
              ></path>
              <path d="M54.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C54.2,5,54.8,5.6,54.8,6.3L54.8,6.3z"></path>
              <path d="M74.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C74.2,5,74.8,5.6,74.8,6.3L74.8,6.3z"></path>
              <path
                d="M84.7,41.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C84.1,39.9,84.7,40.5,84.7,41.2L84.7,41.2z
                        "
              ></path>
              <path
                d="M134.8,16.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C134.2,15,134.8,15.6,134.8,16.3
                        L134.8,16.3z"
              ></path>
              <path
                d="M114.8,36.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C114.2,34.9,114.8,35.5,114.8,36.3
                        L114.8,36.3z"
              ></path>
              <path
                d="M126.9,11.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C125.3,7.9,126.9,9.4,126.9,11.3
                        L126.9,11.3z"
              ></path>
              <path
                d="M116.9,16.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C115.3,12.8,116.9,14.4,116.9,16.3
                        L116.9,16.3z"
              ></path>
              <path d="M124.8,31.3c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S124.8,30.5,124.8,31.3L124.8,31.3z"></path>
              <path d="M125.7,21.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C124.7,19,125.7,20,125.7,21.3L125.7,21.3z"></path>
              <path d="M94.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C94.2,5,94.8,5.6,94.8,6.3L94.8,6.3z"></path>
              <path d="M124.8,1.4c0,0.7-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C124.2,0,124.8,0.6,124.8,1.4L124.8,1.4z"></path>
              <path d="M134.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C134.2,5,134.8,5.6,134.8,6.3L134.8,6.3z"></path>
            </g>
          </svg>
        </div>
      </div>

      <div className="main_section right flex column content--space-between items--start">
        <div className="section_title_container flex row content--space-between items--start">
          <h1 className="section_title red">О проекте</h1>
        </div>
        <div className="section_content">
          Wunderkammer&nbsp;&mdash; партнерский проект Университета ИТМО и&nbsp;Государственного музея-заповедника
          &laquo;Петергоф&raquo;. Его цель&nbsp;&mdash; создание информационно-образовательной среды о&nbsp;Петре Великом
          и&nbsp;базы данных с&nbsp;инструментарием для архивации историкокультурной информации. Основой проекта станут
          верифицированные исторические и&nbsp;культурные метаданные музеев-участников Ассамблеи петровских музеев России.{" "}
          <br />
          Проект реализуется в&nbsp;рамках программы стратегического академического лидерства &laquo;Приоритет 2030&raquo;
          Правительства Российской Федерации.
        </div>

        <div className="itmo_logo_container mobile-only">
          <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 176.9 69.3">
            <g>
              <path
                d="M9.4,59c0.2-0.4,0.2-0.8,0.1-1.1c-0.1-0.2-0.3-0.4-0.5-0.5c-0.6-0.2-1.1,0.1-1.4,0.9l-2.3,5.6L2,58.2
                        c-0.2-0.4-0.4-0.6-0.7-0.7c-0.3-0.1-0.6-0.1-0.9,0.1C0.2,57.7,0,58,0,58.3c0,0.3,0.1,0.6,0.3,0.9L4.2,66c-0.2,0.4-0.4,0.8-0.6,1
                        c-0.2,0.2-0.4,0.3-0.7,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.4-0.1C2.1,67.2,2,67.1,2,67.1c-0.6-0.2-1,0-1.2,0.5
                        c-0.2,0.6,0,1,0.6,1.4c0.4,0.2,1,0.3,1.7,0.3c0.8,0,1.4-0.3,1.9-0.9c0.5-0.6,1-1.5,1.5-2.7L9.4,59L9.4,59z"
              ></path>
              <path
                d="M12.6,69.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-4h5.2v4c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3
                        c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-9.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.8v3.7h-5.2v-3.7c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8C11.9,69.1,12.2,69.2,12.6,69.2L12.6,69.2z"
              ></path>
              <path
                d="M25.2,69.2c0.3,0,0.7-0.2,1-0.6l5.4-7.3v6.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.5,0.3-0.8v-9.6c0-0.7-0.3-1.1-1-1.1c-0.4,0-0.7,0.2-1,0.6l-5.3,7.4v-6.9c0-0.3-0.1-0.6-0.3-0.8
                        c-0.2-0.2-0.5-0.3-0.8-0.3c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8
                        C24.6,69.1,24.9,69.2,25.2,69.2L25.2,69.2z"
              ></path>
              <path
                d="M43.5,62.9c0.4-0.2,0.8-0.5,1-0.9c0.3-0.5,0.4-1,0.4-1.5c0-2.1-1.5-3.1-4.3-3.1c-1,0-1.9,0.1-2.7,0.2c-0.7,0.1-1,0.5-1,1.2
                        v9.2c0,0.7,0.3,1.1,1,1.1c0.8,0.1,1.8,0.2,2.9,0.2c3.2,0,4.8-1.2,4.8-3.6c0-0.7-0.2-1.3-0.6-1.8C44.5,63.4,44.1,63.1,43.5,62.9
                        L43.5,62.9z M40.5,62.3h-1.7v-3.1c0.5-0.1,1-0.1,1.5-0.1c1.5,0,2.3,0.5,2.3,1.5C42.7,61.8,42,62.3,40.5,62.3L40.5,62.3z M42.6,67
                        c-0.4,0.3-1,0.4-1.8,0.4c-0.7,0-1.4,0-1.9-0.1V64h1.9c1.7,0,2.5,0.6,2.5,1.7C43.2,66.3,43,66.8,42.6,67L42.6,67z"
              ></path>
              <path
                d="M55.8,68.9c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-4.8v-3.1h3.7c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3h-3.7v-2.8h4.7c0.3,0,0.6-0.1,0.7-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-5.7c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8
                        v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3H55C55.3,69.2,55.6,69.1,55.8,68.9L55.8,68.9z"
              ></path>
              <path
                d="M66.4,61.1c0-1.3-0.4-2.3-1.2-2.9c-0.8-0.6-1.9-0.9-3.3-0.9c-1,0-1.9,0.1-2.6,0.2c-0.7,0.1-1,0.6-1,1.2v9.3
                        c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-3c0.3,0,0.8,0.1,1.4,0.1
                        c1.4,0,2.5-0.3,3.3-1C65.9,63.4,66.4,62.4,66.4,61.1L66.4,61.1z M60.4,59.3c0.5-0.1,0.9-0.1,1.4-0.1c1.6,0,2.3,0.6,2.3,1.9
                        c0,1.4-0.8,2.1-2.4,2.1c-0.5,0-1,0-1.3-0.1V59.3L60.4,59.3z"
              ></path>
              <path
                d="M75.9,66.7c-0.8,0.5-1.6,0.7-2.4,0.7c-1,0-1.8-0.4-2.4-1.1c-0.6-0.7-0.9-1.8-0.9-3c0-1.2,0.3-2.2,0.9-3
                        c0.9-1.2,2.5-1.4,4.6-0.6c0.7,0.3,1.2,0.2,1.4-0.3c0.2-0.5,0.1-1-0.4-1.3c-0.8-0.5-1.8-0.8-3.2-0.8c-1.7,0-3.1,0.6-4,1.7
                        c-1,1.1-1.5,2.6-1.5,4.4c0,1.9,0.5,3.3,1.4,4.4c0.9,1.1,2.3,1.6,4.1,1.6c1.4,0,2.6-0.3,3.5-1c0.3-0.2,0.5-0.5,0.5-0.8
                        c0.1-0.3,0-0.5-0.1-0.7C77,66.4,76.5,66.3,75.9,66.7L75.9,66.7z"
              ></path>
              <path
                d="M88.3,57.4c-0.4,0-0.7,0.2-1,0.6l-5.3,7.4v-6.9c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.4,0-0.6,0.1-0.8,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.8,0.3c0.3,0,0.7-0.2,1-0.6l5.4-7.3v6.8
                        c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-9.6C89.4,57.7,89,57.4,88.3,57.4
                        L88.3,57.4z"
              ></path>
              <path
                d="M101.4,58.3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3h-8c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
                        c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.8,0.3h2.9v8.7c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.5,0.3-0.8v-8.7h2.9c0.3,0,0.6-0.1,0.8-0.3C101.3,58.9,101.4,58.6,101.4,58.3L101.4,58.3z"
              ></path>
              <path
                d="M110.2,67.2h-4.8v-3.1h3.7c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3
                        h-3.7v-2.8h4.7c0.3,0,0.6-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-5.7
                        c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h5.9c0.3,0,0.6-0.1,0.7-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C110.8,67.3,110.5,67.2,110.2,67.2L110.2,67.2z"
              ></path>
              <path
                d="M122.2,58.3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.8-0.3h-8c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
                        c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.8,0.3h2.9v8.7c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.5,0.3-0.8v-8.7h2.9c0.3,0,0.6-0.1,0.8-0.3C122.1,58.9,122.2,58.6,122.2,58.3L122.2,58.3z"
              ></path>
              <path
                d="M138.4,68.1v-9.6c0-0.7-0.3-1.1-1-1.1c-0.4,0-0.7,0.2-1,0.6l-5.3,7.4v-6.9c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
                        c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v9.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.8,0.3c0.3,0,0.7-0.2,1-0.6l5.4-7.3
                        v6.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3C138.3,68.7,138.4,68.4,138.4,68.1L138.4,68.1z"
              ></path>
              <path
                d="M149.3,57.4h-8c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.8,0.3h2.9v8.7
                        c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-8.7h2.9c0.3,0,0.6-0.1,0.8-0.3
                        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C149.9,57.5,149.6,57.4,149.3,57.4L149.3,57.4z"
              ></path>
              <path
                d="M162,57.4c-0.4,0-0.8,0.2-1.1,0.7l-3.3,5.4l-3.4-5.3c-0.3-0.5-0.7-0.7-1.1-0.7c-0.3,0-0.5,0.1-0.7,0.3
                        c-0.2,0.2-0.3,0.5-0.3,0.9v9.6c0,0.7,0.3,1.1,1,1.1c0.7,0,1-0.4,1-1.1v-6.6l2.4,3.7c0.3,0.4,0.6,0.6,1,0.6c0.4,0,0.7-0.2,1-0.6
                        l2.3-3.8v6.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-9.6
                        c0-0.3-0.1-0.6-0.3-0.8C162.6,57.5,162.4,57.4,162,57.4L162,57.4z"
              ></path>
              <path
                d="M175.4,58.9c-1-1.1-2.4-1.7-4.2-1.7c-1.8,0-3.2,0.6-4.2,1.7c-1,1.1-1.5,2.6-1.5,4.4c0,1.8,0.5,3.2,1.5,4.4
                        c1,1.1,2.4,1.7,4.2,1.7c1.8,0,3.2-0.6,4.2-1.7c1-1.1,1.5-2.6,1.5-4.4C176.9,61.5,176.4,60.1,175.4,58.9L175.4,58.9z M173.7,66.2
                        c-0.6,0.8-1.4,1.1-2.5,1.1c-1.1,0-1.9-0.4-2.5-1.1c-0.6-0.8-0.9-1.8-0.9-3s0.3-2.2,0.9-3c0.6-0.8,1.4-1.1,2.5-1.1
                        c1.1,0,1.9,0.4,2.5,1.1c0.6,0.8,0.9,1.8,0.9,3C174.6,64.5,174.3,65.5,173.7,66.2L173.7,66.2z"
              ></path>
              <path d="M55.8,26.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C54.7,24,55.8,25,55.8,26.3L55.8,26.3z"></path>
              <path d="M66.9,11.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C65.4,7.9,66.9,9.4,66.9,11.3L66.9,11.3z"></path>
              <path
                d="M76.9,16.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C75.4,12.8,76.9,14.4,76.9,16.3L76.9,16.3z
                        "
              ></path>
              <path
                d="M86.9,21.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C85.4,17.8,86.9,19.4,86.9,21.3L86.9,21.3z
                        "
              ></path>
              <path
                d="M76.9,26.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C75.4,22.8,76.9,24.4,76.9,26.3L76.9,26.3z
                        "
              ></path>
              <path
                d="M96.9,26.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C95.4,22.8,96.9,24.4,96.9,26.3L96.9,26.3z
                        "
              ></path>
              <path d="M95.8,36.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3C94.7,33.9,95.8,35,95.8,36.3L95.8,36.3z"></path>
              <path d="M105.8,31.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C104.7,29,105.8,30,105.8,31.3L105.8,31.3z"></path>
              <path
                d="M106.9,21.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C105.4,17.8,106.9,19.4,106.9,21.3
                        L106.9,21.3z"
              ></path>
              <path
                d="M96.9,16.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C95.4,12.8,96.9,14.4,96.9,16.3L96.9,16.3z
                        "
              ></path>
              <path d="M85.8,31.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C84.7,29,85.8,30,85.8,31.3L85.8,31.3z"></path>
              <path
                d="M106.9,11.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C105.4,7.9,106.9,9.4,106.9,11.3
                        L106.9,11.3z"
              ></path>
              <path d="M115.7,6.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C114.7,4,115.7,5.1,115.7,6.3L115.7,6.3z"></path>
              <path d="M115.7,26.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C114.7,24,115.7,25,115.7,26.3L115.7,26.3z"></path>
              <path d="M85.8,11.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C84.7,9,85.8,10,85.8,11.3L85.8,11.3z"></path>
              <path d="M75.8,36.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C74.7,33.9,75.8,35,75.8,36.3L75.8,36.3z"></path>
              <path
                d="M66.9,21.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C65.4,17.8,66.9,19.4,66.9,21.3L66.9,21.3z
                        "
              ></path>
              <path
                d="M53.5,14.5c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8C51.7,15.3,52.5,14.5,53.5,14.5L53.5,14.5z
                         M53.5,12.8c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5C56.9,14.4,55.4,12.8,53.5,12.8L53.5,12.8z"
              ></path>
              <path
                d="M44.8,31.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C44.2,29.9,44.8,30.5,44.8,31.3L44.8,31.3z
                        "
              ></path>
              <path
                d="M44.8,21.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C44.2,19.9,44.8,20.6,44.8,21.3L44.8,21.3z
                        "
              ></path>
              <path
                d="M64.8,31.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C64.2,29.8,64.8,30.5,64.8,31.2L64.8,31.2z
                        "
              ></path>
              <path d="M64.8,1.4c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C64.2,0,64.8,0.6,64.8,1.4L64.8,1.4z"></path>
              <path
                d="M84.7,41.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C84.1,39.9,84.7,40.5,84.7,41.2L84.7,41.2z
                        "
              ></path>
              <path d="M54.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C54.2,5,54.8,5.6,54.8,6.3L54.8,6.3z"></path>
              <path d="M74.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C74.2,5,74.8,5.6,74.8,6.3L74.8,6.3z"></path>
              <path
                d="M84.7,41.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C84.1,39.9,84.7,40.5,84.7,41.2L84.7,41.2z
                        "
              ></path>
              <path
                d="M134.8,16.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C134.2,15,134.8,15.6,134.8,16.3
                        L134.8,16.3z"
              ></path>
              <path
                d="M114.8,36.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C114.2,34.9,114.8,35.5,114.8,36.3
                        L114.8,36.3z"
              ></path>
              <path
                d="M126.9,11.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C125.3,7.9,126.9,9.4,126.9,11.3
                        L126.9,11.3z"
              ></path>
              <path
                d="M116.9,16.3c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5C115.3,12.8,116.9,14.4,116.9,16.3
                        L116.9,16.3z"
              ></path>
              <path d="M124.8,31.3c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S124.8,30.5,124.8,31.3L124.8,31.3z"></path>
              <path d="M125.7,21.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C124.7,19,125.7,20,125.7,21.3L125.7,21.3z"></path>
              <path d="M94.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C94.2,5,94.8,5.6,94.8,6.3L94.8,6.3z"></path>
              <path d="M124.8,1.4c0,0.7-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C124.2,0,124.8,0.6,124.8,1.4L124.8,1.4z"></path>
              <path d="M134.8,6.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4C134.2,5,134.8,5.6,134.8,6.3L134.8,6.3z"></path>
            </g>
          </svg>
        </div>
      </div>
    </main>
  );
};

export default About;
