import { Link } from "react-router-dom";
import BigLogoImage from "../components/BigLogoImage";
import { COLLECTION_LIST_URL } from "../utils/urls";

const Collection = () => {
  return (
    <main className="layout_main flex row mobile--column content--space-between mobile-content--start items--start">
      <div className="main_section left desktop-only">
        <div className="section_logo">
          <Link to="/">
            <BigLogoImage />
          </Link>
        </div>

        <div className="key_wrapper flex column content--space-between items--center">
          <div className="key_chain_container flex row content--start">
            <div className="key_chain key_chain_1"></div>
            <div className="key_chain key_chain_2"></div>
          </div>
          <div className="key_container">
            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 203.9 671.4">
              <path
                d="M96.1,637.2c-32.4,10.6-63.9,21-96,31.5c-0.2-7.2-0.1-13.9-0.1-20.8c21.1-6.4,41.9-12.6,63.3-19.1
                        c-5.3-2.8-10.6-3.9-15.7-5.6c-5.3-1.8-10.7-3.4-16.1-5.2c-5.2-1.7-10.4-3.4-15.6-5.1c-5.2-1.7-10.4-3.3-15.7-5c0-5,0-9.8,0-15.2
                        c10-3.9,20.7-6.8,31.2-10.3c10.5-3.5,21-6.9,31.5-11.1c-20.9-6.3-41.8-12.6-62.7-19c0-6.9,0-13.6,0-20.6
                        c31.9,10.5,63.6,20.9,95.8,31.4c-0.4-86.1,0.3-171.7-0.9-257.6c-6.4,0-12.5,0-18.9,0c0-6.1,0-11.9,0-18c5.2,0,10.3,0,15.2,0
                        c1.7-2.6,0.1-4-1.2-5.5c-4.4-4.7-6.4-10.3-6.3-16.8c0.1-5.6,2.4-10.3,5.7-14.6c0.7-0.9,1.6-1.7,2.4-2.5c0-1,0-2,0-2.9
                        c-39.4-7.1-66.7-28.9-80.5-66.4c-6.8-18.7-7.2-38.1-1.9-57.4C20.2,82.2,53.1,54,92.7,48.9c-0.9-0.9-1.7-1.7-2.5-2.4
                        c-8.3-6.5-11.5-17.2-8.5-27.9C85,6.9,96.1,1.1,104.1,0.2c14.1-1.7,26,10.2,28.1,21.4c1.7,9-3.9,22.3-12.4,27.6
                        c30,5.5,53.3,20.6,69.3,46.2c10.9,17.4,15.9,36.7,14.5,57.1c-3.1,46-35.5,84.4-85.1,92.6c0.9,1,1.5,1.9,2.3,2.5
                        c11.7,8.4,12.6,26.3,1.3,36.1c-1.4,1.3-3.1,2.3-5.2,3.8c5.9,0,11.2,0,16.7,0c0,6.1,0,11.8,0,18c-6.1,0-12.2,0-18.7,0
                        c0.1,122,0,243.6,1.1,365.4c-3.5,1.2-6.8,0.5-10.1,0.6c-3.1,0.1-6.3,0-9.9,0C96.1,660,96.1,648.9,96.1,637.2z M104.5,152.5
                        c5.4-4.2,56.7-55.9,58.5-59c-31.5-36-89.2-32.9-116.7,0.8C65.6,113.6,84.9,132.9,104.5,152.5z M175.4,183.6c-47.2,0-93.8,0-141,0
                        c15.4,26.4,37.7,41.5,67.7,42.4C134.6,227,158.7,212,175.4,183.6z M119.1,165.1c20.5,0,41.6,0,62.9,0c4.2-19.7,2-38.3-7.4-55.6
                        C155.9,128.3,137.4,146.9,119.1,165.1z M35.2,110.1c-9.6,14.2-12.2,43.8-6.6,55.1c20.7,0,41.2,0,61.7,0
                        C71.9,146.7,53.5,128.4,35.2,110.1z M95.4,620.8c0.9-5.5,0.7-37.4-0.1-41.1c-21,6.7-42,13.4-63,20.1c0,0.3,0,0.6,0,0.9
                        C53.3,607.4,74.3,614,95.4,620.8z M106.5,39.2c3.7-0.8,7.2-1.2,9.4-4.5c2-3.1,3.6-6.4,3-10.2c-1-6.2-5.1-10.7-12.6-11.1
                        c-6.5-0.3-11.1,4.9-12.2,11.5C93,31.7,97.7,36.9,106.5,39.2z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <div className="main_section right flex column content--space-between items--start">
        <div className="section_title_container flex row content--space-between items--start">
          <h1 className="section_title">Коллекция</h1>
          <div className="anchor_container_mobile key anchor mobile-only">
            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 203.9 671.4">
              <path
                d="M96.1,637.2c-32.4,10.6-63.9,21-96,31.5c-0.2-7.2-0.1-13.9-0.1-20.8c21.1-6.4,41.9-12.6,63.3-19.1
                    c-5.3-2.8-10.6-3.9-15.7-5.6c-5.3-1.8-10.7-3.4-16.1-5.2c-5.2-1.7-10.4-3.4-15.6-5.1c-5.2-1.7-10.4-3.3-15.7-5c0-5,0-9.8,0-15.2
                    c10-3.9,20.7-6.8,31.2-10.3c10.5-3.5,21-6.9,31.5-11.1c-20.9-6.3-41.8-12.6-62.7-19c0-6.9,0-13.6,0-20.6
                    c31.9,10.5,63.6,20.9,95.8,31.4c-0.4-86.1,0.3-171.7-0.9-257.6c-6.4,0-12.5,0-18.9,0c0-6.1,0-11.9,0-18c5.2,0,10.3,0,15.2,0
                    c1.7-2.6,0.1-4-1.2-5.5c-4.4-4.7-6.4-10.3-6.3-16.8c0.1-5.6,2.4-10.3,5.7-14.6c0.7-0.9,1.6-1.7,2.4-2.5c0-1,0-2,0-2.9
                    c-39.4-7.1-66.7-28.9-80.5-66.4c-6.8-18.7-7.2-38.1-1.9-57.4C20.2,82.2,53.1,54,92.7,48.9c-0.9-0.9-1.7-1.7-2.5-2.4
                    c-8.3-6.5-11.5-17.2-8.5-27.9C85,6.9,96.1,1.1,104.1,0.2c14.1-1.7,26,10.2,28.1,21.4c1.7,9-3.9,22.3-12.4,27.6
                    c30,5.5,53.3,20.6,69.3,46.2c10.9,17.4,15.9,36.7,14.5,57.1c-3.1,46-35.5,84.4-85.1,92.6c0.9,1,1.5,1.9,2.3,2.5
                    c11.7,8.4,12.6,26.3,1.3,36.1c-1.4,1.3-3.1,2.3-5.2,3.8c5.9,0,11.2,0,16.7,0c0,6.1,0,11.8,0,18c-6.1,0-12.2,0-18.7,0
                    c0.1,122,0,243.6,1.1,365.4c-3.5,1.2-6.8,0.5-10.1,0.6c-3.1,0.1-6.3,0-9.9,0C96.1,660,96.1,648.9,96.1,637.2z M104.5,152.5
                    c5.4-4.2,56.7-55.9,58.5-59c-31.5-36-89.2-32.9-116.7,0.8C65.6,113.6,84.9,132.9,104.5,152.5z M175.4,183.6c-47.2,0-93.8,0-141,0
                    c15.4,26.4,37.7,41.5,67.7,42.4C134.6,227,158.7,212,175.4,183.6z M119.1,165.1c20.5,0,41.6,0,62.9,0c4.2-19.7,2-38.3-7.4-55.6
                    C155.9,128.3,137.4,146.9,119.1,165.1z M35.2,110.1c-9.6,14.2-12.2,43.8-6.6,55.1c20.7,0,41.2,0,61.7,0
                    C71.9,146.7,53.5,128.4,35.2,110.1z M95.4,620.8c0.9-5.5,0.7-37.4-0.1-41.1c-21,6.7-42,13.4-63,20.1c0,0.3,0,0.6,0,0.9
                    C53.3,607.4,74.3,614,95.4,620.8z M106.5,39.2c3.7-0.8,7.2-1.2,9.4-4.5c2-3.1,3.6-6.4,3-10.2c-1-6.2-5.1-10.7-12.6-11.1
                    c-6.5-0.3-11.1,4.9-12.2,11.5C93,31.7,97.7,36.9,106.5,39.2z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="section_content">
          Ассамблея петровских музеев России объединяет свыше 110 культурных институций, каждая из которых бережно хранит память
          о Петре Великом. С помощью платформы «Сорока» мы хотим создать цифровую коллекцию первого русского императора:
          музейные предметы, памятники, города, легенды, утраченное и любое другое наследие петровской эпохи.
        </div>
        <div className="section_footer">
          <Link className="section_footer_more" to={COLLECTION_LIST_URL}>
            перейти к коллекции
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Collection;
