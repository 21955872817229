import { Link } from "react-router-dom";
import BigLogoImage from "../components/BigLogoImage";
import { INTERACTIVE_MAP_URL } from "../utils/urls";

const Map = () => {
  return (
    <main className="layout_main flex row mobile--column content--space-between mobile-content--start items--start">
      <div className="main_section left desktop-only">
        <div className="section_logo">
          <Link to="/">
            <BigLogoImage />
          </Link>
        </div>

        <div className="dividers_wrapper flex column content--space-between items--center">
          <div className="dividers_top_container flex row content--start items--start">
            <div className="dividers_top_left_container flex row content--end items--end">
              <div className="dividers_container">
                <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 633.5 659.8">
                  <path
                    d="M175.4,426.3c-0.7,1.1-1.7,2.4-2.5,3.8c-3.8,7.1-7.3,14.3-11.5,21.2c-3.4,5.6-6.3,11.4-9.7,17c-3.8,6.2-7.1,12.7-10.9,19
                                c-2.1,3.6-4,7.4-6.1,11c-2.8,4.7-5.2,9.5-8,14.2c-2,3.4-3.7,7.1-5.8,10.6c-4.6,7.7-8.4,15.9-13.4,23.5c-2.4,3.6-3.9,7.7-6.1,11.4
                                c-3.9,6.7-7.4,13.7-11.6,20.2c-2.8,4.4-4.9,9.3-7.4,13.9c-2,3.7-4.4,7.2-6.4,10.9c-2.1,4-4.5,7.8-6.5,11.8
                                c-3.4,7.1-8.4,13.4-11.2,20.8c-3.3,2.9-3.9,7.4-6.6,10.8c-2.5,3.1-3.9,7-5.8,10.6c-0.4,0.7-0.7,1.4-1.2,2.3
                                c-6.1-1.8-10.8-6.1-16.8-8.5c2.8-5.7,5.8-10.8,8.9-15.8c2.2-3.6,4-7.3,6-11c3.7-7.1,7.9-14,11.7-21.1c2.1-3.9,4.3-7.9,6.6-11.8
                                c2.2-3.7,4.6-7.5,5.9-11.5c1.3-4,3.7-7.5,4.8-11.5c1.2-4.1,2.9-8.1,4.4-12.2c1.9-5.3,2.8-10.8,4-16.3c0.9-4.2,1.4-8.5,2.1-12.6
                                c0.8-4.1,0.2-8.2,1-12.1c0.6-2.9,0.9-5.7,0.8-8.6c-0.1-2.7,0.4-5.4-0.8-8.2c-0.8-1.9-0.3-4.4,0.8-6.5c3.2-6.3,6.2-12.8,9.2-19.2
                                c2.6-5.4,5.4-10.8,7.7-16.3c2.1-5.1,4.7-10,7.1-15.1c2.7-5.7,5.3-11.5,8-17.2c2.3-5,4.7-10.1,7-15.1c0.8-1.7,1.5-3.4,2.1-4.9
                                c-3.4-2.1-6.7-3.9-9.8-5.9c-4.3-2.8-8.4-6.1-12.9-8.7c-3.4-2-6.3-4.8-9.6-6.9c-3.2-2-5.9-4.9-9.2-6.9c-1.4-0.9-2.5-2-3.8-2.9
                                c-1.1-0.9-2.4-1.5-3.4-2.5c-5.3-5.3-11.5-9.5-17-14.7c-4.1-3.8-8-7.7-12.2-11.5c-2.8-2.5-5.2-5.3-8.3-7.6c-2.4-1.7-4-4.6-6.1-6.9
                                c-3-3.1-6.2-6.1-9.1-9.2c-1.7-1.7-3.1-3.7-4.6-5.6c-1.4-1.7-3.5-2.9-4.3-5.2c-0.2-0.7-1.1-1.2-1.7-1.8c-5.1-5-8.8-11-13.5-16.8
                                c15.6-11.7,31-23.2,46.5-34.8c3.8,2.5,5.8,6.8,9,10c0.5,0.5,0.8,1.4,1.3,1.9c3.4,3.8,7,7.3,10.2,11.3c7.6,9.5,16.8,17.3,25.2,26
                                c2,2.1,4.4,3.8,6.6,5.7c2.2,1.9,3.9,4.3,6.6,5.8c2.5,1.4,4.5,3.7,6.7,5.6c6.4,5.3,13,10.2,19.7,15.1c6.1,4.5,12.8,8.1,19,13.4
                                c1.1-3.2,3.1-5.6,3.8-8.7c0.5-2,2-3.8,2.9-5.8c3.7-7.8,7.4-15.6,11-23.4c2.3-5,4.7-10.1,7-15.1c1.2-2.5,2.3-5.1,3.5-7.6
                                c1.1-2.4,2.6-4.6,3.5-7.1c2.2-6,5.5-11.4,8.1-17.2c2.3-5.1,4.6-10.1,7-15.1c2.6-5.6,5.5-11,7.9-16.7c2.2-5.1,4.7-10,7.1-15.1
                                c2.3-5,4.6-10.1,7-15.1c2.6-5.6,5.6-11,7.9-16.7c2.2-5.5,5.2-10.5,7.4-15.9c2.2-5.5,5.3-10.5,7.6-15.9c2.2-5.3,4.8-10.3,7.2-15.5
                                c2.5-5.6,5.6-11,7.9-16.7c2.1-5.3,5.1-10.2,7.1-15.5c0.5-1.3,1.1-2.5,1.7-3.8c-2.6-5.5-5.3-11.1-5.8-17.4c-2.3-2.5-0.4-5.8-1.7-8.8
                                c-1.1-2.5-0.6-5.9-0.2-8.9c1-8.1,2.2-16,5.3-23.8c2.6-6.3,6-12.1,9.9-17.3c3.3-4.4,7.5-8.2,12-11.7c3.8-2.9,7.8-5.4,11.8-7.8
                                c4.7-2.8,12.6-5,18.5-6.1c4.9-1,9.8-1.6,14.7-1.9c2-0.1,4.1,0.6,6.1,0.9c2,0.4,4.3-1,6.1,1c3.7-0.8,6.6,2,10.2,2
                                c0.4,0,0.9,0.2,1.3,0.5c5.3,2.7,10.7,4.8,15.6,8.4c6.7,5,10.9,8.9,15.8,14.8c3.5,4.3,6.3,9,8.8,13.9c2.4,4.7,3.8,9.8,5.1,14.9
                                c0.7,2.7,0.2,5.6,1,8.1c1,3.1,0.9,6.1,0.8,9.1c0,2-0.6,4.1-1,6.1c-0.3,1.9,0.9,4-0.9,5.6c-0.1,4.8-1.9,9.1-4.1,13.3
                                c-0.4,0.8-0.4,1.8-0.7,3c2.8,5.9,5.7,12.1,8.6,18.4c3.1,6.6,6.2,13.1,9.3,19.7c2.2,4.7,4.8,9.4,6.7,14.2c2,5.4,4.9,10.3,7.2,15.5
                                c2.4,5.5,5.6,10.7,7.8,16.3c2.2,5.5,5.1,10.6,7.3,16c2.3,5.4,5.4,10.5,7.6,15.8c2.2,5.4,5,10.6,7.3,16c2.2,5.2,5.2,10.2,7.3,15.5
                                c1.9,4.8,4.5,9.1,6.5,13.8c2.6,6.1,5.7,12,8.3,18c2.3,5.4,5.4,10.5,7.6,15.9c2.2,5.3,4.8,10.4,7.1,15.5c2.6,5.6,5.5,11,7.9,16.7
                                c2.2,5.1,4.7,10,7.1,15.1s4.6,10.1,7,15.1c2.6,5.6,5.6,11,7.9,16.7c2.2,5.5,5.2,10.5,7.4,15.9c2.2,5.5,5.4,10.5,7.6,15.9
                                c2.2,5.4,5,10.6,7.4,16c2.1,4.8,4.8,9.4,6.7,14.2c2.3,5.9,5.4,11.4,8.1,17.2c2.3,5,4.6,10.1,7,15.1c2.6,5.6,5.4,11.1,8,16.7
                                c1.9,4.2,3.6,8.4,3.1,13.2c-0.4,5,0.2,10-0.2,15c-0.4,5,0.5,9.8,1.3,14.6c0.9,5.5,1.8,11.1,3.3,16.6c1.5,5.2,2.5,10.6,4.6,15.6
                                c1.9,4.5,3.4,9.2,5.6,13.7c2.4,4.8,5.1,9.5,7,14.6c1.2,3.2,3.1,6.1,4.9,8.9c0.9,1.5,1.7,3,2.5,4.5c1.1,2,2.2,4.1,3.7,6
                                c2.5,3.3,3.7,7.4,6,11c2.9,4.4,5,9.3,7.8,13.8c2.4,4,4.1,8.4,7.1,12.1c0.8,1,0.8,2.6,1.3,4.4c-5.1,2.9-10.4,5.9-15.9,9
                                c-0.4-0.7-0.8-1.6-1.3-2.5c-4.9-7.8-8.6-16.2-13.7-23.8c-2.4-3.6-3.9-7.7-6.1-11.4c-3.9-6.7-7.4-13.7-11.5-20.2
                                c-2.8-4.4-4.9-9.3-7.4-13.9c-2-3.7-4.3-7.2-6.4-10.9c-0.7-1.2-1.1-2.6-1.8-3.7c-4.5-7.1-7.9-14.8-12.6-21.8c-2.8-4.2-4.7-9-7.2-13.5
                                c-2-3.7-4.2-7.2-6.3-10.9c-0.3-0.5-0.4-1.2-0.7-1.7c-4-7.2-7.8-14.5-12-21.5c-3.5-5.9-6.6-11.9-10-17.9c-4.7-8.4-9.4-16.8-14.1-25.2
                                c-4.6-8.3-9.1-16.6-14-24.7c-2.1-3.4-3.1-7.6-6.7-10.4c-5.7,1.9-11.4,3.7-17.1,5.6c-5.3,1.8-10.6,3.5-16.1,4.6
                                c-0.7,0.1-1.4,0.7-2.1,1c-5.3,1.8-10.9,2.6-16.3,4.1c-5,1.3-10.1,2-14.9,3.8c-2.2,0.8-4.8-0.3-7,1.2c-5.2-0.1-10.1,1.6-15.1,2.2
                                c-2.7,0.3-5.5,0.2-8.1,1c-2.6,0.7-5.3,0.1-7.6,1c-4,1.6-8.2,0.5-12.3,1.6c-3.9,1.1-8.4-0.4-12.4,0.4c-8,1.6-16.1,0.6-24.1,0.8
                                c-8.2,0.2-16.4,0.6-24.5-0.2c-5.6-0.5-11.1-0.6-16.6-0.8c-2-0.1-3.9-1.3-6.1-1c-2,0.3-4.1-0.3-6.1-0.8c-3.9-0.9-8-0.8-12-1.2
                                c-1.5-0.2-3.1-0.7-4.6-1c-1.5-0.3-3,0-4.7-0.8c-1.8-0.9-4.3-0.3-6.5-1.1c-2.8-0.9-6-0.8-9-1.2c-1.7-0.2-3.8-0.1-5-1.1
                                c-1.9-1.6-4.1-0.2-6-1.4c-1.5-0.9-3.5-1.1-5.4-1.5c-2-0.4-4.1-0.6-6.1-1c-1.8-0.4-3.6-0.9-5.4-1.5c-6.9-2.2-13.9-4.2-20.9-6.3
                                c-3-0.9-5.8-2.2-8.7-3.1C182,428,179,427.3,175.4,426.3z M330.9,148c-1.5,2.9-2.9,5.6-4.4,8.3c-2.5,4.5-5,8.9-7.4,13.4
                                c-2.2,3.9-4.5,7.8-6.6,11.8c-2.4,4.4-4.6,8.8-7.2,13c-3.7,6.1-7.1,12.4-10.7,18.6c-4.7,8.2-9.5,16.4-14,24.7
                                c-3.9,7.2-8.2,14.3-12,21.5c-1.9,3.6-3.6,7.2-5.7,10.6c-2.2,3.7-4.5,7.4-6.5,11.3c-2.3,4.6-4.6,9.1-7.4,13.4
                                c-2.4,3.8-4.5,7.8-6.7,11.7c-2.3,4.4-4.6,8.8-7.2,13c-4.3,7-8.2,14.3-12.1,21.5c-1.9,3.5-3.8,7.1-5.9,10.5
                                c-4.5,7.2-7.9,15-12.7,22.2c1.3,0.5,2.5,0.9,3.7,1.3c4.9,1.6,9.8,2.9,14.8,4.3c6.1,1.6,12.2,3.7,18.5,4.8c1.7,2.1,4.2,0.7,6.3,1.6
                                c3.3,1.4,7,1.8,10.5,2.4c1.9,0.3,3.7,0.2,5.6,0.9c1.7,0.7,3.9-0.3,5.5,1.1c2.8-0.7,5.3,1.1,8.1,1c2.8-0.1,5.4,0.3,8.1,0.9
                                c2.6,0.6,5.3,0.1,8.1,0.9c2.6,0.8,5.6,0,8.4,0.2c2.7,0.2,5.4,0.9,8.1,0.9c7.7,0.1,15.3,0.2,23,0c7.5-0.2,15.1,0.7,22.6-0.8
                                c3.7-0.8,7.8,0.4,11.3-0.6c3.6-1,7.4,0.3,10.9-1.4c1.9-1,4.4,0.4,6.7-0.8c2.1-1.1,4.8,0.4,6.9-1.3c4.7,0.1,9.2-1.1,13.7-2
                                c2.2-0.4,4.9,0,6.5-1.2c2.1-1.6,4.7,0.4,6.3-1.8c4.4-0.5,8.6-1.7,12.8-2.9c5.7-1.6,11.4-3.1,17-4.9c2.8-0.9,5.7-1.6,8.9-2.5
                                c-0.4-1.4-0.5-2.9-1.3-3.9c-4.3-5.8-7.2-12.4-10.8-18.6c-4.8-8.3-9.4-16.7-14.1-25.1c-4.6-8.3-9.2-16.5-13.9-24.7
                                c-3.5-6-6.7-12.2-10.1-18.3c-4.1-7.1-8-14.3-12-21.5c-1.5-2.7-2.7-5.6-4.3-8.2c-4.6-7.2-8.3-14.9-12.6-22.3c-2.2-3.7-4-7.7-6.2-11.4
                                c-2.9-4.8-5.3-9.8-8.3-14.6c-2.1-3.4-3.7-7.2-5.8-10.6c-3.8-6.2-7.1-12.8-10.8-19c-2.7-4.7-5.3-9.5-7.9-14.2c-0.3-0.5-0.6-1.1-1-1.6
                                C335.4,155.7,333.2,152,330.9,148z M348.7,140.2c1.3,2.7,2.2,5.7,3.9,8.2c4.6,6.9,8.1,14.3,12.3,21.4c3.6,6.2,7.1,12.4,10.6,18.6
                                c1.8,3.2,3,6.8,5.1,9.9c3.1,4.5,5.3,9.5,8.1,14.2c2.2,3.6,3.9,7.4,6.1,11c3.9,6.2,7.1,12.8,10.8,19c2.7,4.7,5.3,9.5,7.9,14.2
                                c1.9,3.4,3.6,6.9,5.6,10.2c2.2,3.7,4.5,7.4,6.5,11.3c2.3,4.6,4.6,9.1,7.4,13.4c2.4,3.8,4.5,7.8,6.7,11.7c2.3,4.4,4.6,8.8,7.2,13
                                c3.7,6.1,6.9,12.5,10.7,18.6c2.5,3.9,4.4,8.2,6.8,12.1c2.5,4.1,4.8,8.4,7,12.6c3.9,7.2,8.2,14.2,12,21.5c1.9,3.6,3.6,7.2,5.7,10.6
                                c2.2,3.7,4.5,7.4,6.5,11.3c2.3,4.5,4.6,9.1,7.3,13.4c2.4,3.8,4.5,7.8,6.7,11.7c2.3,4.4,4.6,8.8,7.2,13c4.4,7.2,8.1,14.7,12.5,21.8
                                c0.7,1.2,1.1,2.5,1.8,3.7c2.1,3.6,4.4,7.2,6.4,10.9c2.4,4.5,4.3,9.3,7.1,13.5c4.8,7.3,8.7,15.1,12.9,22.7c0.4,0.8,0.9,1.5,2,1.8
                                c0-5-0.2-9.6,0.1-14.3c0.2-3.3-0.8-6.4-2.2-9.1c-2.6-5.2-5.4-10.4-7.7-15.8c-2.3-5.4-5.1-10.5-7.3-16c-2.2-5.6-5.4-10.7-7.8-16.3
                                c-2.3-5.2-5.1-10.2-7.2-15.5c-2.3-5.7-5.3-11.1-7.9-16.7c-2.4-5-4.7-10.1-7-15.1c-2.3-5-4.7-10.1-7-15.1c-2.7-5.7-5.7-11.2-8.1-17.2
                                c-1.9-4.9-4.6-9.4-6.7-14.2c-2.7-6.1-5.9-11.8-8.4-18c-1.8-4.7-4.5-9.1-6.6-13.8c-3.5-7.9-7.5-15.6-11.2-23.3
                                c-0.9-1.9-2.1-3.8-2.8-5.8c-2.2-7-6.3-13-8.9-19.8c-1.9-5-4.8-9.7-6.8-14.7c-2.4-5.8-5.5-11.4-8-17.2c-1.9-4.2-4.7-8.1-6.2-12.4
                                c-2.3-6.8-6.3-12.7-8.7-19.4c-1.6-4.5-4.2-8.5-6-13c-2.6-6.4-6.2-12.4-8.8-18.8c-1.9-4.5-4.4-8.8-6.3-13.4
                                c-2.8-6.5-6.2-12.8-9.1-19.2c-1.9-4.2-4.2-8.2-5.9-12.6c-1.2-3.2-2.5-6.6-5-9.3C372.8,130.2,361.7,136.8,348.7,140.2z M279.5,121.3
                                c-0.9,1.1-1.6,1.8-2,2.6c-0.7,1.4-1.1,2.9-1.7,4.3c-2.6,6.1-6,11.8-8.4,18c-1.8,4.9-4.6,9.4-6.8,14.2c-2.4,5.2-4.7,10.4-7.1,15.5
                                c-2.6,5.7-5.8,11.2-8.1,17.2c-1.9,4.9-4.6,9.4-6.7,14.2c-2.7,6.1-5.9,11.8-8.4,18c-1.8,4.7-4.5,9.1-6.6,13.8
                                c-3.5,7.9-7.5,15.6-11.2,23.3c-0.9,1.9-2.1,3.8-2.8,5.8c-2.2,7-6.3,13-8.9,19.8c-1.9,5-4.8,9.6-6.8,14.7c-2.4,5.8-5.5,11.4-8,17.2
                                c-1.8,4.2-4.6,8.1-6.2,12.4c-2.5,6.9-6.3,13-9,19.8c-1.5,3.9-3.6,7.5-5.5,11.4c2.8,2.3,6.7,2.2,9.1,4.9c2.4,2.6,5.2,4.8,7.1,8.1
                                c2.5-4.4,4.7-8.4,7-12.5c2.2-3.9,4-8.1,6.4-11.8c2.8-4.3,4.9-9,7.6-13.4c2.2-3.7,4-7.7,6.3-11.4c3.8-6.1,7-12.5,10.7-18.6
                                c4.8-8.1,9.3-16.5,14-24.7c4.7-8.4,9.4-16.8,14.1-25.1c4.6-8.3,9.2-16.5,13.9-24.7c4.7-8.4,9.4-16.8,14-25.2
                                c4.7-8.4,9.2-16.8,14.1-25.2c3.4-5.9,6.7-11.9,10-17.8c3.9-7,7.7-14.1,11.6-21.2c0.7-1.2,1.4-2.4,2.3-3.7
                                C300.9,137.2,288.9,132.2,279.5,121.3z M465.2,387.7c-0.6,0.6-0.8,0.9-1,1c-7.9,2.1-15.5,5.4-23.5,7.3c-3.9,0.9-7.8,2-11.8,3
                                c-3.3,0.9-6.9,1.3-10.1,2.3c-5.1,1.6-10.4,1.8-15.5,3.6c-1.9,0.7-4,0.2-6.1,1c-1.8,0.7-4,0.6-6.1,1c-5.5,0.9-11.1,1.1-16.7,2.1
                                c-2.8,0.5-5.7,0.5-8.6,0.9c-4.9,0.7-10,0.6-15.1,1c-9.4,0.7-19-0.1-28.5,0.3c-9.4,0.4-18.7-1.5-28.1-1.1c-0.3,0-0.8,0.1-1,0
                                c-2.4-1.7-5.1-0.7-7.6-1c-2.5-0.3-5.1-0.7-7.6-1c-3.4-0.4-6.8-0.3-10-1.2c-1.6-0.4-3.1-0.6-4.6-0.9c-1.5-0.2-3-0.7-4.6-1
                                c-2.7-0.4-5.5-0.3-8-1.2c-3.5-1.3-7.3-2.1-10.8-2.6c-5.2-0.8-9.9-3.2-15.2-3.3c-2.2-1.6-5.1-1.2-7.5-2.4c-2.3-1.2-5.3-0.6-7.4-2.5
                                c-3,0.9-5-1.8-7.6-2.1c-2.2-0.3-4.1-2-6.2-1.9c-4.1,7.3-8,14.3-11.9,21.2c0.7,0.7,1,1.2,1.4,1.3c4,1.5,8.1,3.1,12.2,4.4
                                c4.9,1.5,9.9,2.7,14.8,4.1c3.1,0.9,6.1,2.6,9.3,2.8c5.6,0.5,10.6,3.4,16.2,3.2c5.4,2.4,11.3,2.3,16.9,3.9c1.8,0.5,3.7,0.2,5.6,0.9
                                c1.8,0.7,4.1,0.7,6.1,0.9c2.8,0.4,5.7,1,8.6,1.1c2.9,0.1,5.7,0.6,8.6,1c2.7,0.4,5.4,0.2,8.1,0.9c2.8,0.7,6,0,9,0.2
                                c3,0.2,6.1,0.9,9.1,0.9c7.8,0.1,15.7,0.2,23.5,0c7.9-0.2,15.7,0.7,23.6-0.9c4.6-0.9,9.4,0.3,14.1-1c2.8-0.8,6-0.3,9.1-1
                                c2.9-0.7,6-0.2,9.1-1c3.2-0.9,6.8-0.3,10-1.2c4.9-1.4,10-1.3,14.8-2.8c2.1-0.7,4.8-0.1,6.5-1.2c2.1-1.5,4.3-0.5,6.4-1.5
                                c2.6-1.2,5.7-1.7,8.5-2.1c2-0.3,4-0.9,6-1.3c1.9-0.3,3.8-1.4,5.7-1.9c3.4-0.9,6.8-2.2,10.3-2.9c7-1.4,13.2-4.9,20.4-6.7
                                C473.6,402.7,469.6,395.4,465.2,387.7z M329.3,124c3.4-0.3,6.4-0.4,9.5-0.9c6-0.9,11.4-3,16.7-5.7c7.3-3.7,13.2-9.3,17.8-15.9
                                c5.7-8.2,8.6-17.6,9.2-27.6c0.3-5.2-0.7-10.1-1.8-15.2c-1.6-7-4.6-13.4-8.9-18.8c-5.9-7.3-12.9-13.5-21.9-17
                                c-5.6-2.2-11.5-3.2-17.4-3.9c-3.2-0.4-6.4,0.3-9.6,0.7c-8.2,1-15.4,3.9-22.1,8.4c-4.1,2.7-7.9,5.7-10.9,9.8
                                c-3.5,4.7-6.8,9.3-8.9,14.8c-1.6,4.3-2,8.8-3.4,13.1c-0.5,1.7-0.1,3.6-0.1,5.5c0.1,3.1,0.2,6,0.8,9.1c1,5.1,2.7,9.9,4.7,14.6
                                c1.5,3.3,3.9,6.1,5.9,9.1c4.1,6.1,9.9,10,15.9,13.6C312.4,122,320.8,124,329.3,124z M44.3,279c-2.5,1.7-4.9,3.2-7.2,4.9
                                c-2.2,1.6-4.2,3.5-6.4,5.2c-2.1,1.7-5.1,2.4-6.5,5.1c1.2,1.2,2.5,2.2,3.3,3.4c4.8,7.1,11,12.9,17,18.9c0.6,0.6,1.5,1.1,1.7,1.8
                                c0.9,2.7,3.1,4.4,5.1,5.8c3.2,2.2,5.7,4.9,8.3,7.6c6.4,6.4,13.1,12.3,20,18.1c5.4,4.6,10.9,9,16.5,13.3c4.3,3.3,8.5,6.7,12.9,9.8
                                c4.7,3.3,9.6,6.5,14.5,9.6c3,1.9,6.1,3.8,9.3,5.8c3.7-7.8,7.2-15.3,10.7-22.8C105.6,342,72.7,312.9,44.3,279z M147.9,400.6
                                c-2.2,5-4.3,9.6-6.4,14.2c-2.3,4.9-4.8,9.6-6.9,14.6c-2.5,6-5.9,11.5-8.1,17.6c-1.8,4.9-4.6,9.4-6.7,14.2
                                c-2.3,5.4-5.2,10.5-7.3,15.9c-2.2,5.6-5.2,10.8-7.9,16.2c-1.5,3.1-2.2,6.2-2,9.7c0.2,4.6,0.1,9.2,0.1,14.4c1.9-3.3,3.5-5.9,5-8.6
                                c3.2-6,6.5-12,10-17.9c4.8-8.3,9.4-16.8,14-25.1c4-7.2,8-14.3,12-21.5c1.5-2.7,2.6-5.6,4.4-8.2c3.3-4.8,5.7-10,8.6-15
                                c2.2-3.7,4.3-7.6,6.5-11.3c0.8-1.4,2.2-2.8,1.4-4.7C158.3,406.4,153.4,403.4,147.9,400.6z"
                  ></path>
                  <path
                    d="M321.2,102.6C309.8,99,302.4,91.5,298.7,80c-0.3-1.7-0.9-3.8-1.2-6c-0.5-4.7,0.6-9.3,2.3-13.5c1.9-5,4.6-9.9,9-13.2
                                c5-3.7,10.3-7.2,17-7.3c1.5,0,3.2-1.2,4.5-0.8c3.8,1.1,7.8,0.7,11.4,2.5c4.6,2.3,9.2,4.5,12.4,8.6c3.7,4.8,6.8,10,7.5,16.4
                                c0.2,2,1.1,4.1,0.8,6.1c-0.8,4-1.3,8-2.9,11.8c-2.9,6.5-7.4,11.7-13.5,15.2c-2.9,1.7-6.4,3-10.1,3.5c-3.1,0.5-6.1,0.9-9.1,0.7
                                C324.9,103.8,322.9,103,321.2,102.6z M323.4,83.4c3.4,1.7,6.5,1.7,9.4,1.5c1.2-0.7,2.2-1.4,3.3-2c4.8-2.3,7.2-6.5,7.2-11.4
                                c0.1-6-3.8-10.9-9-12.6c-1.9-0.6-4-1.8-6-1c-2.9,1.1-5.9,2-8.1,4.6c-5,6.3-4.7,11.6-0.1,17.4C321.2,81.4,322.6,82.6,323.4,83.4z"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="dividers_top_right_container"></div>
          </div>
          <div className="dividers_bottom_container flex row content--start items--start">
            <div className="dividers_bottom_left_container"></div>
            <div className="dividers_bottom_right_container"></div>
          </div>
        </div>
      </div>

      <div className="main_section right flex column content--space-between items--start">
        <div className="section_title_container flex row content--space-between items--start">
          <h1 className="section_title">Карта</h1>
          <div className="anchor_container_mobile dividers mobile-only">
            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 633.5 659.8">
              <path
                d="M175.4,426.3c-0.7,1.1-1.7,2.4-2.5,3.8c-3.8,7.1-7.3,14.3-11.5,21.2c-3.4,5.6-6.3,11.4-9.7,17c-3.8,6.2-7.1,12.7-10.9,19
                                c-2.1,3.6-4,7.4-6.1,11c-2.8,4.7-5.2,9.5-8,14.2c-2,3.4-3.7,7.1-5.8,10.6c-4.6,7.7-8.4,15.9-13.4,23.5c-2.4,3.6-3.9,7.7-6.1,11.4
                                c-3.9,6.7-7.4,13.7-11.6,20.2c-2.8,4.4-4.9,9.3-7.4,13.9c-2,3.7-4.4,7.2-6.4,10.9c-2.1,4-4.5,7.8-6.5,11.8
                                c-3.4,7.1-8.4,13.4-11.2,20.8c-3.3,2.9-3.9,7.4-6.6,10.8c-2.5,3.1-3.9,7-5.8,10.6c-0.4,0.7-0.7,1.4-1.2,2.3
                                c-6.1-1.8-10.8-6.1-16.8-8.5c2.8-5.7,5.8-10.8,8.9-15.8c2.2-3.6,4-7.3,6-11c3.7-7.1,7.9-14,11.7-21.1c2.1-3.9,4.3-7.9,6.6-11.8
                                c2.2-3.7,4.6-7.5,5.9-11.5c1.3-4,3.7-7.5,4.8-11.5c1.2-4.1,2.9-8.1,4.4-12.2c1.9-5.3,2.8-10.8,4-16.3c0.9-4.2,1.4-8.5,2.1-12.6
                                c0.8-4.1,0.2-8.2,1-12.1c0.6-2.9,0.9-5.7,0.8-8.6c-0.1-2.7,0.4-5.4-0.8-8.2c-0.8-1.9-0.3-4.4,0.8-6.5c3.2-6.3,6.2-12.8,9.2-19.2
                                c2.6-5.4,5.4-10.8,7.7-16.3c2.1-5.1,4.7-10,7.1-15.1c2.7-5.7,5.3-11.5,8-17.2c2.3-5,4.7-10.1,7-15.1c0.8-1.7,1.5-3.4,2.1-4.9
                                c-3.4-2.1-6.7-3.9-9.8-5.9c-4.3-2.8-8.4-6.1-12.9-8.7c-3.4-2-6.3-4.8-9.6-6.9c-3.2-2-5.9-4.9-9.2-6.9c-1.4-0.9-2.5-2-3.8-2.9
                                c-1.1-0.9-2.4-1.5-3.4-2.5c-5.3-5.3-11.5-9.5-17-14.7c-4.1-3.8-8-7.7-12.2-11.5c-2.8-2.5-5.2-5.3-8.3-7.6c-2.4-1.7-4-4.6-6.1-6.9
                                c-3-3.1-6.2-6.1-9.1-9.2c-1.7-1.7-3.1-3.7-4.6-5.6c-1.4-1.7-3.5-2.9-4.3-5.2c-0.2-0.7-1.1-1.2-1.7-1.8c-5.1-5-8.8-11-13.5-16.8
                                c15.6-11.7,31-23.2,46.5-34.8c3.8,2.5,5.8,6.8,9,10c0.5,0.5,0.8,1.4,1.3,1.9c3.4,3.8,7,7.3,10.2,11.3c7.6,9.5,16.8,17.3,25.2,26
                                c2,2.1,4.4,3.8,6.6,5.7c2.2,1.9,3.9,4.3,6.6,5.8c2.5,1.4,4.5,3.7,6.7,5.6c6.4,5.3,13,10.2,19.7,15.1c6.1,4.5,12.8,8.1,19,13.4
                                c1.1-3.2,3.1-5.6,3.8-8.7c0.5-2,2-3.8,2.9-5.8c3.7-7.8,7.4-15.6,11-23.4c2.3-5,4.7-10.1,7-15.1c1.2-2.5,2.3-5.1,3.5-7.6
                                c1.1-2.4,2.6-4.6,3.5-7.1c2.2-6,5.5-11.4,8.1-17.2c2.3-5.1,4.6-10.1,7-15.1c2.6-5.6,5.5-11,7.9-16.7c2.2-5.1,4.7-10,7.1-15.1
                                c2.3-5,4.6-10.1,7-15.1c2.6-5.6,5.6-11,7.9-16.7c2.2-5.5,5.2-10.5,7.4-15.9c2.2-5.5,5.3-10.5,7.6-15.9c2.2-5.3,4.8-10.3,7.2-15.5
                                c2.5-5.6,5.6-11,7.9-16.7c2.1-5.3,5.1-10.2,7.1-15.5c0.5-1.3,1.1-2.5,1.7-3.8c-2.6-5.5-5.3-11.1-5.8-17.4c-2.3-2.5-0.4-5.8-1.7-8.8
                                c-1.1-2.5-0.6-5.9-0.2-8.9c1-8.1,2.2-16,5.3-23.8c2.6-6.3,6-12.1,9.9-17.3c3.3-4.4,7.5-8.2,12-11.7c3.8-2.9,7.8-5.4,11.8-7.8
                                c4.7-2.8,12.6-5,18.5-6.1c4.9-1,9.8-1.6,14.7-1.9c2-0.1,4.1,0.6,6.1,0.9c2,0.4,4.3-1,6.1,1c3.7-0.8,6.6,2,10.2,2
                                c0.4,0,0.9,0.2,1.3,0.5c5.3,2.7,10.7,4.8,15.6,8.4c6.7,5,10.9,8.9,15.8,14.8c3.5,4.3,6.3,9,8.8,13.9c2.4,4.7,3.8,9.8,5.1,14.9
                                c0.7,2.7,0.2,5.6,1,8.1c1,3.1,0.9,6.1,0.8,9.1c0,2-0.6,4.1-1,6.1c-0.3,1.9,0.9,4-0.9,5.6c-0.1,4.8-1.9,9.1-4.1,13.3
                                c-0.4,0.8-0.4,1.8-0.7,3c2.8,5.9,5.7,12.1,8.6,18.4c3.1,6.6,6.2,13.1,9.3,19.7c2.2,4.7,4.8,9.4,6.7,14.2c2,5.4,4.9,10.3,7.2,15.5
                                c2.4,5.5,5.6,10.7,7.8,16.3c2.2,5.5,5.1,10.6,7.3,16c2.3,5.4,5.4,10.5,7.6,15.8c2.2,5.4,5,10.6,7.3,16c2.2,5.2,5.2,10.2,7.3,15.5
                                c1.9,4.8,4.5,9.1,6.5,13.8c2.6,6.1,5.7,12,8.3,18c2.3,5.4,5.4,10.5,7.6,15.9c2.2,5.3,4.8,10.4,7.1,15.5c2.6,5.6,5.5,11,7.9,16.7
                                c2.2,5.1,4.7,10,7.1,15.1s4.6,10.1,7,15.1c2.6,5.6,5.6,11,7.9,16.7c2.2,5.5,5.2,10.5,7.4,15.9c2.2,5.5,5.4,10.5,7.6,15.9
                                c2.2,5.4,5,10.6,7.4,16c2.1,4.8,4.8,9.4,6.7,14.2c2.3,5.9,5.4,11.4,8.1,17.2c2.3,5,4.6,10.1,7,15.1c2.6,5.6,5.4,11.1,8,16.7
                                c1.9,4.2,3.6,8.4,3.1,13.2c-0.4,5,0.2,10-0.2,15c-0.4,5,0.5,9.8,1.3,14.6c0.9,5.5,1.8,11.1,3.3,16.6c1.5,5.2,2.5,10.6,4.6,15.6
                                c1.9,4.5,3.4,9.2,5.6,13.7c2.4,4.8,5.1,9.5,7,14.6c1.2,3.2,3.1,6.1,4.9,8.9c0.9,1.5,1.7,3,2.5,4.5c1.1,2,2.2,4.1,3.7,6
                                c2.5,3.3,3.7,7.4,6,11c2.9,4.4,5,9.3,7.8,13.8c2.4,4,4.1,8.4,7.1,12.1c0.8,1,0.8,2.6,1.3,4.4c-5.1,2.9-10.4,5.9-15.9,9
                                c-0.4-0.7-0.8-1.6-1.3-2.5c-4.9-7.8-8.6-16.2-13.7-23.8c-2.4-3.6-3.9-7.7-6.1-11.4c-3.9-6.7-7.4-13.7-11.5-20.2
                                c-2.8-4.4-4.9-9.3-7.4-13.9c-2-3.7-4.3-7.2-6.4-10.9c-0.7-1.2-1.1-2.6-1.8-3.7c-4.5-7.1-7.9-14.8-12.6-21.8c-2.8-4.2-4.7-9-7.2-13.5
                                c-2-3.7-4.2-7.2-6.3-10.9c-0.3-0.5-0.4-1.2-0.7-1.7c-4-7.2-7.8-14.5-12-21.5c-3.5-5.9-6.6-11.9-10-17.9c-4.7-8.4-9.4-16.8-14.1-25.2
                                c-4.6-8.3-9.1-16.6-14-24.7c-2.1-3.4-3.1-7.6-6.7-10.4c-5.7,1.9-11.4,3.7-17.1,5.6c-5.3,1.8-10.6,3.5-16.1,4.6
                                c-0.7,0.1-1.4,0.7-2.1,1c-5.3,1.8-10.9,2.6-16.3,4.1c-5,1.3-10.1,2-14.9,3.8c-2.2,0.8-4.8-0.3-7,1.2c-5.2-0.1-10.1,1.6-15.1,2.2
                                c-2.7,0.3-5.5,0.2-8.1,1c-2.6,0.7-5.3,0.1-7.6,1c-4,1.6-8.2,0.5-12.3,1.6c-3.9,1.1-8.4-0.4-12.4,0.4c-8,1.6-16.1,0.6-24.1,0.8
                                c-8.2,0.2-16.4,0.6-24.5-0.2c-5.6-0.5-11.1-0.6-16.6-0.8c-2-0.1-3.9-1.3-6.1-1c-2,0.3-4.1-0.3-6.1-0.8c-3.9-0.9-8-0.8-12-1.2
                                c-1.5-0.2-3.1-0.7-4.6-1c-1.5-0.3-3,0-4.7-0.8c-1.8-0.9-4.3-0.3-6.5-1.1c-2.8-0.9-6-0.8-9-1.2c-1.7-0.2-3.8-0.1-5-1.1
                                c-1.9-1.6-4.1-0.2-6-1.4c-1.5-0.9-3.5-1.1-5.4-1.5c-2-0.4-4.1-0.6-6.1-1c-1.8-0.4-3.6-0.9-5.4-1.5c-6.9-2.2-13.9-4.2-20.9-6.3
                                c-3-0.9-5.8-2.2-8.7-3.1C182,428,179,427.3,175.4,426.3z M330.9,148c-1.5,2.9-2.9,5.6-4.4,8.3c-2.5,4.5-5,8.9-7.4,13.4
                                c-2.2,3.9-4.5,7.8-6.6,11.8c-2.4,4.4-4.6,8.8-7.2,13c-3.7,6.1-7.1,12.4-10.7,18.6c-4.7,8.2-9.5,16.4-14,24.7
                                c-3.9,7.2-8.2,14.3-12,21.5c-1.9,3.6-3.6,7.2-5.7,10.6c-2.2,3.7-4.5,7.4-6.5,11.3c-2.3,4.6-4.6,9.1-7.4,13.4
                                c-2.4,3.8-4.5,7.8-6.7,11.7c-2.3,4.4-4.6,8.8-7.2,13c-4.3,7-8.2,14.3-12.1,21.5c-1.9,3.5-3.8,7.1-5.9,10.5
                                c-4.5,7.2-7.9,15-12.7,22.2c1.3,0.5,2.5,0.9,3.7,1.3c4.9,1.6,9.8,2.9,14.8,4.3c6.1,1.6,12.2,3.7,18.5,4.8c1.7,2.1,4.2,0.7,6.3,1.6
                                c3.3,1.4,7,1.8,10.5,2.4c1.9,0.3,3.7,0.2,5.6,0.9c1.7,0.7,3.9-0.3,5.5,1.1c2.8-0.7,5.3,1.1,8.1,1c2.8-0.1,5.4,0.3,8.1,0.9
                                c2.6,0.6,5.3,0.1,8.1,0.9c2.6,0.8,5.6,0,8.4,0.2c2.7,0.2,5.4,0.9,8.1,0.9c7.7,0.1,15.3,0.2,23,0c7.5-0.2,15.1,0.7,22.6-0.8
                                c3.7-0.8,7.8,0.4,11.3-0.6c3.6-1,7.4,0.3,10.9-1.4c1.9-1,4.4,0.4,6.7-0.8c2.1-1.1,4.8,0.4,6.9-1.3c4.7,0.1,9.2-1.1,13.7-2
                                c2.2-0.4,4.9,0,6.5-1.2c2.1-1.6,4.7,0.4,6.3-1.8c4.4-0.5,8.6-1.7,12.8-2.9c5.7-1.6,11.4-3.1,17-4.9c2.8-0.9,5.7-1.6,8.9-2.5
                                c-0.4-1.4-0.5-2.9-1.3-3.9c-4.3-5.8-7.2-12.4-10.8-18.6c-4.8-8.3-9.4-16.7-14.1-25.1c-4.6-8.3-9.2-16.5-13.9-24.7
                                c-3.5-6-6.7-12.2-10.1-18.3c-4.1-7.1-8-14.3-12-21.5c-1.5-2.7-2.7-5.6-4.3-8.2c-4.6-7.2-8.3-14.9-12.6-22.3c-2.2-3.7-4-7.7-6.2-11.4
                                c-2.9-4.8-5.3-9.8-8.3-14.6c-2.1-3.4-3.7-7.2-5.8-10.6c-3.8-6.2-7.1-12.8-10.8-19c-2.7-4.7-5.3-9.5-7.9-14.2c-0.3-0.5-0.6-1.1-1-1.6
                                C335.4,155.7,333.2,152,330.9,148z M348.7,140.2c1.3,2.7,2.2,5.7,3.9,8.2c4.6,6.9,8.1,14.3,12.3,21.4c3.6,6.2,7.1,12.4,10.6,18.6
                                c1.8,3.2,3,6.8,5.1,9.9c3.1,4.5,5.3,9.5,8.1,14.2c2.2,3.6,3.9,7.4,6.1,11c3.9,6.2,7.1,12.8,10.8,19c2.7,4.7,5.3,9.5,7.9,14.2
                                c1.9,3.4,3.6,6.9,5.6,10.2c2.2,3.7,4.5,7.4,6.5,11.3c2.3,4.6,4.6,9.1,7.4,13.4c2.4,3.8,4.5,7.8,6.7,11.7c2.3,4.4,4.6,8.8,7.2,13
                                c3.7,6.1,6.9,12.5,10.7,18.6c2.5,3.9,4.4,8.2,6.8,12.1c2.5,4.1,4.8,8.4,7,12.6c3.9,7.2,8.2,14.2,12,21.5c1.9,3.6,3.6,7.2,5.7,10.6
                                c2.2,3.7,4.5,7.4,6.5,11.3c2.3,4.5,4.6,9.1,7.3,13.4c2.4,3.8,4.5,7.8,6.7,11.7c2.3,4.4,4.6,8.8,7.2,13c4.4,7.2,8.1,14.7,12.5,21.8
                                c0.7,1.2,1.1,2.5,1.8,3.7c2.1,3.6,4.4,7.2,6.4,10.9c2.4,4.5,4.3,9.3,7.1,13.5c4.8,7.3,8.7,15.1,12.9,22.7c0.4,0.8,0.9,1.5,2,1.8
                                c0-5-0.2-9.6,0.1-14.3c0.2-3.3-0.8-6.4-2.2-9.1c-2.6-5.2-5.4-10.4-7.7-15.8c-2.3-5.4-5.1-10.5-7.3-16c-2.2-5.6-5.4-10.7-7.8-16.3
                                c-2.3-5.2-5.1-10.2-7.2-15.5c-2.3-5.7-5.3-11.1-7.9-16.7c-2.4-5-4.7-10.1-7-15.1c-2.3-5-4.7-10.1-7-15.1c-2.7-5.7-5.7-11.2-8.1-17.2
                                c-1.9-4.9-4.6-9.4-6.7-14.2c-2.7-6.1-5.9-11.8-8.4-18c-1.8-4.7-4.5-9.1-6.6-13.8c-3.5-7.9-7.5-15.6-11.2-23.3
                                c-0.9-1.9-2.1-3.8-2.8-5.8c-2.2-7-6.3-13-8.9-19.8c-1.9-5-4.8-9.7-6.8-14.7c-2.4-5.8-5.5-11.4-8-17.2c-1.9-4.2-4.7-8.1-6.2-12.4
                                c-2.3-6.8-6.3-12.7-8.7-19.4c-1.6-4.5-4.2-8.5-6-13c-2.6-6.4-6.2-12.4-8.8-18.8c-1.9-4.5-4.4-8.8-6.3-13.4
                                c-2.8-6.5-6.2-12.8-9.1-19.2c-1.9-4.2-4.2-8.2-5.9-12.6c-1.2-3.2-2.5-6.6-5-9.3C372.8,130.2,361.7,136.8,348.7,140.2z M279.5,121.3
                                c-0.9,1.1-1.6,1.8-2,2.6c-0.7,1.4-1.1,2.9-1.7,4.3c-2.6,6.1-6,11.8-8.4,18c-1.8,4.9-4.6,9.4-6.8,14.2c-2.4,5.2-4.7,10.4-7.1,15.5
                                c-2.6,5.7-5.8,11.2-8.1,17.2c-1.9,4.9-4.6,9.4-6.7,14.2c-2.7,6.1-5.9,11.8-8.4,18c-1.8,4.7-4.5,9.1-6.6,13.8
                                c-3.5,7.9-7.5,15.6-11.2,23.3c-0.9,1.9-2.1,3.8-2.8,5.8c-2.2,7-6.3,13-8.9,19.8c-1.9,5-4.8,9.6-6.8,14.7c-2.4,5.8-5.5,11.4-8,17.2
                                c-1.8,4.2-4.6,8.1-6.2,12.4c-2.5,6.9-6.3,13-9,19.8c-1.5,3.9-3.6,7.5-5.5,11.4c2.8,2.3,6.7,2.2,9.1,4.9c2.4,2.6,5.2,4.8,7.1,8.1
                                c2.5-4.4,4.7-8.4,7-12.5c2.2-3.9,4-8.1,6.4-11.8c2.8-4.3,4.9-9,7.6-13.4c2.2-3.7,4-7.7,6.3-11.4c3.8-6.1,7-12.5,10.7-18.6
                                c4.8-8.1,9.3-16.5,14-24.7c4.7-8.4,9.4-16.8,14.1-25.1c4.6-8.3,9.2-16.5,13.9-24.7c4.7-8.4,9.4-16.8,14-25.2
                                c4.7-8.4,9.2-16.8,14.1-25.2c3.4-5.9,6.7-11.9,10-17.8c3.9-7,7.7-14.1,11.6-21.2c0.7-1.2,1.4-2.4,2.3-3.7
                                C300.9,137.2,288.9,132.2,279.5,121.3z M465.2,387.7c-0.6,0.6-0.8,0.9-1,1c-7.9,2.1-15.5,5.4-23.5,7.3c-3.9,0.9-7.8,2-11.8,3
                                c-3.3,0.9-6.9,1.3-10.1,2.3c-5.1,1.6-10.4,1.8-15.5,3.6c-1.9,0.7-4,0.2-6.1,1c-1.8,0.7-4,0.6-6.1,1c-5.5,0.9-11.1,1.1-16.7,2.1
                                c-2.8,0.5-5.7,0.5-8.6,0.9c-4.9,0.7-10,0.6-15.1,1c-9.4,0.7-19-0.1-28.5,0.3c-9.4,0.4-18.7-1.5-28.1-1.1c-0.3,0-0.8,0.1-1,0
                                c-2.4-1.7-5.1-0.7-7.6-1c-2.5-0.3-5.1-0.7-7.6-1c-3.4-0.4-6.8-0.3-10-1.2c-1.6-0.4-3.1-0.6-4.6-0.9c-1.5-0.2-3-0.7-4.6-1
                                c-2.7-0.4-5.5-0.3-8-1.2c-3.5-1.3-7.3-2.1-10.8-2.6c-5.2-0.8-9.9-3.2-15.2-3.3c-2.2-1.6-5.1-1.2-7.5-2.4c-2.3-1.2-5.3-0.6-7.4-2.5
                                c-3,0.9-5-1.8-7.6-2.1c-2.2-0.3-4.1-2-6.2-1.9c-4.1,7.3-8,14.3-11.9,21.2c0.7,0.7,1,1.2,1.4,1.3c4,1.5,8.1,3.1,12.2,4.4
                                c4.9,1.5,9.9,2.7,14.8,4.1c3.1,0.9,6.1,2.6,9.3,2.8c5.6,0.5,10.6,3.4,16.2,3.2c5.4,2.4,11.3,2.3,16.9,3.9c1.8,0.5,3.7,0.2,5.6,0.9
                                c1.8,0.7,4.1,0.7,6.1,0.9c2.8,0.4,5.7,1,8.6,1.1c2.9,0.1,5.7,0.6,8.6,1c2.7,0.4,5.4,0.2,8.1,0.9c2.8,0.7,6,0,9,0.2
                                c3,0.2,6.1,0.9,9.1,0.9c7.8,0.1,15.7,0.2,23.5,0c7.9-0.2,15.7,0.7,23.6-0.9c4.6-0.9,9.4,0.3,14.1-1c2.8-0.8,6-0.3,9.1-1
                                c2.9-0.7,6-0.2,9.1-1c3.2-0.9,6.8-0.3,10-1.2c4.9-1.4,10-1.3,14.8-2.8c2.1-0.7,4.8-0.1,6.5-1.2c2.1-1.5,4.3-0.5,6.4-1.5
                                c2.6-1.2,5.7-1.7,8.5-2.1c2-0.3,4-0.9,6-1.3c1.9-0.3,3.8-1.4,5.7-1.9c3.4-0.9,6.8-2.2,10.3-2.9c7-1.4,13.2-4.9,20.4-6.7
                                C473.6,402.7,469.6,395.4,465.2,387.7z M329.3,124c3.4-0.3,6.4-0.4,9.5-0.9c6-0.9,11.4-3,16.7-5.7c7.3-3.7,13.2-9.3,17.8-15.9
                                c5.7-8.2,8.6-17.6,9.2-27.6c0.3-5.2-0.7-10.1-1.8-15.2c-1.6-7-4.6-13.4-8.9-18.8c-5.9-7.3-12.9-13.5-21.9-17
                                c-5.6-2.2-11.5-3.2-17.4-3.9c-3.2-0.4-6.4,0.3-9.6,0.7c-8.2,1-15.4,3.9-22.1,8.4c-4.1,2.7-7.9,5.7-10.9,9.8
                                c-3.5,4.7-6.8,9.3-8.9,14.8c-1.6,4.3-2,8.8-3.4,13.1c-0.5,1.7-0.1,3.6-0.1,5.5c0.1,3.1,0.2,6,0.8,9.1c1,5.1,2.7,9.9,4.7,14.6
                                c1.5,3.3,3.9,6.1,5.9,9.1c4.1,6.1,9.9,10,15.9,13.6C312.4,122,320.8,124,329.3,124z M44.3,279c-2.5,1.7-4.9,3.2-7.2,4.9
                                c-2.2,1.6-4.2,3.5-6.4,5.2c-2.1,1.7-5.1,2.4-6.5,5.1c1.2,1.2,2.5,2.2,3.3,3.4c4.8,7.1,11,12.9,17,18.9c0.6,0.6,1.5,1.1,1.7,1.8
                                c0.9,2.7,3.1,4.4,5.1,5.8c3.2,2.2,5.7,4.9,8.3,7.6c6.4,6.4,13.1,12.3,20,18.1c5.4,4.6,10.9,9,16.5,13.3c4.3,3.3,8.5,6.7,12.9,9.8
                                c4.7,3.3,9.6,6.5,14.5,9.6c3,1.9,6.1,3.8,9.3,5.8c3.7-7.8,7.2-15.3,10.7-22.8C105.6,342,72.7,312.9,44.3,279z M147.9,400.6
                                c-2.2,5-4.3,9.6-6.4,14.2c-2.3,4.9-4.8,9.6-6.9,14.6c-2.5,6-5.9,11.5-8.1,17.6c-1.8,4.9-4.6,9.4-6.7,14.2
                                c-2.3,5.4-5.2,10.5-7.3,15.9c-2.2,5.6-5.2,10.8-7.9,16.2c-1.5,3.1-2.2,6.2-2,9.7c0.2,4.6,0.1,9.2,0.1,14.4c1.9-3.3,3.5-5.9,5-8.6
                                c3.2-6,6.5-12,10-17.9c4.8-8.3,9.4-16.8,14-25.1c4-7.2,8-14.3,12-21.5c1.5-2.7,2.6-5.6,4.4-8.2c3.3-4.8,5.7-10,8.6-15
                                c2.2-3.7,4.3-7.6,6.5-11.3c0.8-1.4,2.2-2.8,1.4-4.7C158.3,406.4,153.4,403.4,147.9,400.6z"
              ></path>
              <path
                d="M321.2,102.6C309.8,99,302.4,91.5,298.7,80c-0.3-1.7-0.9-3.8-1.2-6c-0.5-4.7,0.6-9.3,2.3-13.5c1.9-5,4.6-9.9,9-13.2
                                c5-3.7,10.3-7.2,17-7.3c1.5,0,3.2-1.2,4.5-0.8c3.8,1.1,7.8,0.7,11.4,2.5c4.6,2.3,9.2,4.5,12.4,8.6c3.7,4.8,6.8,10,7.5,16.4
                                c0.2,2,1.1,4.1,0.8,6.1c-0.8,4-1.3,8-2.9,11.8c-2.9,6.5-7.4,11.7-13.5,15.2c-2.9,1.7-6.4,3-10.1,3.5c-3.1,0.5-6.1,0.9-9.1,0.7
                                C324.9,103.8,322.9,103,321.2,102.6z M323.4,83.4c3.4,1.7,6.5,1.7,9.4,1.5c1.2-0.7,2.2-1.4,3.3-2c4.8-2.3,7.2-6.5,7.2-11.4
                                c0.1-6-3.8-10.9-9-12.6c-1.9-0.6-4-1.8-6-1c-2.9,1.1-5.9,2-8.1,4.6c-5,6.3-4.7,11.6-0.1,17.4C321.2,81.4,322.6,82.6,323.4,83.4z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="section_content">
          Читая, слушая, рассуждая о жизни Петра Великого каждый раз удивляешься сколько мест он посетил, городов и фабрик
          основал, побед одержал, а поражений преодолел. Находясь в постоянном необузданном физическом и ментальном движении, он
          с каждым годом раскручивал всё сильнее и сильнее маятник истории, изменяя не только Россию, но весь окружающий мир.
          Увидеть это становится возможно с помощью интерактивной карты петровской эпохи, которая наглядно покажет как
          «преображался» и «жил» мир.
        </div>
        <div className="section_footer">
          <Link className="section_footer_more" to={INTERACTIVE_MAP_URL}>
            перейти к карте
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Map;
